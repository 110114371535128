import React, {FC, useState} from 'react';
import asyncModal from "react-async-modal";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {ReactSVG} from "react-svg";
import close from "../assets/icons/close.svg";
import girl from "./assets/images/girl.png";
import {thumb} from "../modules/utils";
import HomeStore from "../store/HomeStore";
import {AuthRecovery, AuthSign} from "../pages/AuthPage";


interface Props {
  resolve(success: boolean | number): void;
  defaultType?: 'login'|'register';
  defaultImageId?: string | null;
}

const ModalAuth: FC<Props> = ({defaultType, defaultImageId, resolve}) => {
  const [modalType, setModalType] = useState<'login'|'register'|'recovery'>(defaultType || 'login');

  const handleClose = () => {
    resolve(false);
  }

  return (
    <div className='modal-container big modal-with_img'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose}/>
      <div className="modal-with_img-image">
        <img src={thumb(defaultImageId || HomeStore.randomModels?.[0]?.image?.id, 800) || girl} alt="girl"/>
      </div>
      <div className='modal-with_img-content'>
        {modalType === 'recovery'
          ?
          <AuthRecovery onTypeChange={() => setModalType('register')}/>
          :
            <AuthSign
              authType={modalType}
              onTypeChange={setModalType}
              onFinish={resolve}
            />
        }
      </div>
    </div>
  );
}

const openModalLogin = (defaultType?: 'login'|'register', defaultImageId?: string | null): Promise<boolean> => {
  return asyncModal((props) => <ModalAuth defaultType={defaultType} defaultImageId={defaultImageId} {...props}/>, {}, {
    showCloseIcon: false,
    center: true,
    blockScroll: true
  });
};

export {openModalLogin};