import React, {FC} from 'react';
import {observer} from "mobx-react";
import home from "../../assets/icons/home.svg";
import chat from "../../assets/icons/chat.svg";
import heart from "../../assets/icons/heart.svg";
import diamond from "../../assets/icons/diamond.svg";
import magic_wand from "../../assets/icons/magic_wand.svg";
import AppStore from "../../store/AppStore";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactSVG} from "react-svg";
import useAuth from "../../hooks/useAuth";
import {useTranslation} from "react-i18next";

type TMenu = { path: string, title?: string, icon?: any, needUser?: boolean }

const menus: TMenu[] = [
  {path: '/', title: 'EXPLORE', icon: home, needUser: false},
  {path: '/chat', title: 'CHAT', icon: chat, needUser: true},
  {path: '/premium', title: 'PREMIUM', icon: diamond, needUser: true},

]

interface Props {
}

const Footer: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const [onAuthClick] = useAuth()
  const navigate = useNavigate();
  const location = useLocation();

  const onNavClick = (menu: TMenu) => () => {
    if (!AppStore.user?.id && menu.needUser) {
      return onAuthClick();
    }
    navigate(menu.path);
  }

  const addActiveClass = (path: string): string => {
    if (path === '/' && location.pathname === '/') return ' active';
    if (location.pathname.includes(path.slice(1)) && path !== '/') return ' active';
    return '';
  }

  return (
    <footer>
      <nav>
        <ul>
          {menus.map((menu) => (
            <li key={menu.path}>
              <div className={`menu-item${addActiveClass(menu.path)}`} onClick={onNavClick(menu)}>
                <ReactSVG src={menu.icon} className='react-icon'/>
                <span className='menu-item-text'>{t(menu.title!)}</span>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
})

export default Footer;