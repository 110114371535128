import {useEffect} from 'react';
import {IUser} from "../modules/rest";
import { openSetUpModal, openWarning18Modal} from "../modals";


const useSetUp = (user?: IUser|null) => {
  useEffect(() => {
    init();
  }, [user?.id]);

  const init = async () => {
    if (user?.id) {
      if (!user.name || !user.gender) {
        await openSetUpModal();
      } else if (!localStorage.getItem('confirm18+')) {
        await openWarning18Modal();
      }
    }
  }

}

export default useSetUp;