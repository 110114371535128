import React, {FC, HTMLProps} from 'react';
import {observer} from "mobx-react";
import AppStore from "../store/AppStore";
import {ESubscriptionStatus} from "../modules/rest";
import {openModalNoMoney} from "../modals";
import Button from "./Button";
import diamond from "../assets/icons/diamond.svg";

interface Props extends HTMLProps<any> {
  isNsfw?: boolean;
  enableClick?: boolean;
}

const BlurComponent: FC<Props> = observer(({enableClick, className, isNsfw, onClick, ...props}) => {
  const isPrem = AppStore.user?.subscription?.status === ESubscriptionStatus.Active;

  const handleClick = (e: any) => {
    if (onClick && (isPrem || enableClick)) onClick(e);
  }

  const handleContainerClick = () => {
    if (!isPrem) {
      openModalNoMoney('chat');
    }
  }

  isNsfw = isNsfw && !isPrem;

  return (
    <div className={isNsfw ? ' blur-container' : ''} onClick={handleContainerClick}>
      <div className={`${className || ''}${isNsfw ? ' blur-content' : ''}`} onClick={handleClick} {...props}>
        {props.children}
      </div>
      {isNsfw &&
      <Button
        size='sm'
        className='blur-btn'
        title={'BECOME_PREMIUM'}
        icon={diamond}
      />
      }
    </div>
  );
})

export default BlurComponent;