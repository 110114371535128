import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";

interface Props {
}

const BlockedContentPolicy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='BLOCKED_CONTENT_POLICY'>
          <p>At Silda Group LTD, we are committed to providing a secure, respectful, and lawful online environment for all users. Even though users can not upload any content on our Passioz.com platform (the “APP”), but can “only” chat and initiate the creation of AI generated content, which is accessible only to the specific user in his/her private space and is not accessible publicly, there are categories of content strictly forbidden on our APP and outlined in the present Blocked Content Policy.</p>
          <h4>1. Prohibited Content</h4>
          <p>Prohibited Content</p>

          <p>This policy is applicable to all content, included but not limited to text, images, videos, and any other AI-generated content created on our platform.</p>
<ul>
          <li><b>Illegal Content:</b> Content that contravenes local, national, or international laws and regulations is strictly prohibited. This includes, but is not limited to, content associated with illegal drugs, weapons, violence, or activities that advocate or endorse illegal actions.</li>

          <li><b>Hate Speech and Discrimination:</b> We do not tolerate content that promotes hate speech, discrimination, or harassment based on various factors, including race, ethnicity, nationality, religion, gender, sexual orientation, disability, or any other protected characteristic.</li>

          <li><b>Violence and Harm:</b> Content that encourages, glorifies, or promotes violence, self-harm, or harm to others is strictly prohibited. This includes content that endorses suicide, terrorism, or any form of harm.</li>

          <li><b>Child Exploitation:</b> We unequivocally disallow content that exploits or poses a danger to minors, including but not limited to child pornography, sexual exploitation, or any form of harm or harassment towards minors.</li>

          <li><b>Content Resembling Minors:</b> It is strictly prohibited trying to generate AI content resembling minors. We are committed to ensuring that AI-generated content on our platform does not bear any resemblance to minors or exploit their likeness. Any attempt to generate AI content resembling minors will be promptly reviewed and removed according to our Content Moderation procedures (see paragraph 3).</li>

          <li><b>Infringement on Privacy and Copyright:</b> Any content that infringes upon the privacy, copyrights, trademarks, or intellectual property rights of individuals or entities is not permitted. This includes sharing personal information without consent, pirated content, or any unauthorized use of copyrighted material.</li>

          <li><b>Impersonation and Celebrity Content:</b> Deceptive or harmful impersonation of real individuals, public figures, or celebrities is prohibited. This encompasses any misleading attempts to impersonate or misrepresent others.</li>
</ul>
          <h4>2. Content responsibility</h4>
          <p>You, as a user of the APP are solely responsible for the Output generated by the AI Companions through text messages, voice messages, images, and videos. The AI Companions learn and respond based on the conversations you lead and the parameters you select. You understand and agree that Silda Group LTD does not control or endorse the content generated by the AI Companions. Therefore, you acknowledge that you are fully responsible for the Output generated by the AI and for your own actions while using the APP.</p>

          <p>You must ensure that your interactions with the AI Companions comply with applicable laws, regulations, our Terms and Policies, in particular the Blocked Content Policy, and you shall not engage in any illegal, unethical, or harmful activities through the APP.</p>
          <h4>3. Content Moderation</h4>
          <p>While conversations between users and AI Companions are generally confidential, we have implemented a content moderation filter, based on our proprietary LLM technology to ensure compliance with our Terms and Policies, in particular with our Blocked Content Policy. In the event that the moderation filter detects any content that violates our terms, we reserve the right to manually review the flagged content and take appropriate action, which may include terminating the user's account. This measure is implemented to maintain a respectful and secure environment for all users. We strive to strike a balance between privacy and community standards, and we appreciate your understanding and cooperation in adhering to our guidelines.</p>
          <h4>4. Content Removal and Account Suspension</h4>
          <p>Violations of this Blocked Content Policy may result in content removal, account suspension, or legal action as appropriate.</p>
          <h4>5. Contact Information</h4>
          <p>If you have noticed any violation of these Terms from your perspective, content of any nature whatsoever or you have any questions or require further clarification regarding our Blocked Content Policy, please contact us at: support@passioz.com or directly report in the APP in the “Contact” section.</p>
          <h4>6. Termination</h4>
          <p>We have the right to suspend or terminate the use of the APP by anyone engaged in suspected infringement described above.</p>
    </LegalInformationPageWrapper>
  );
}

export default BlockedContentPolicy;