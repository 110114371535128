import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";

interface Props {
}

const TermsOfServicePage: FC<Props> = () => {
  return (
     <LegalInformationPageWrapper title='TERMS_OF_SERVICE'>
          <p>Welcome to Passioz.com (the “APP”).</p>

          <p>These Terms of Service (the “TOS”) set forth the legally binding terms for your use of the APP owned and operated by Silda Group LTD, Address: 85 Great Portland Street, First Floor, London, United Kingdom, W1W 7LT</p>

          <p>By accessing and/or using the APP you agree to be bound by these TOS. You understand and agree that we will treat your access and/or use of the APP as acceptance of our TOS and of all other Terms and Policies published on https://Passioz.com.</p>

          <p>In the event that you do not agree with our TOS or any other Terms and Policies, please cease using our APP and refrain from accessing any part of our Services.</p>

          <p>The APP is intended for personal and non-commercial use only. You agree not to use the APP for any illegal or unauthorized purpose.</p>

          <p>For purposes of the TOS, “you” and “your” means you as the user of the APP.</p>

          <h4>1. General</h4>
          <p>Passioz.com is an online chat application that uses artificial intelligence (“AI”) algorithms to generate virtual and fictional characters (the “AI Companions”). The APP generates messages, so that you can chat with the AI Companions. The APP can also generate other media including, but not limited to, images, videos and voice notes (the “Services”). Parts of the Services offered by the APP may require you to create a user account.</p>

          <p>To begin with, you need to either pick an AI character you wish to talk to, or generate your own AI character (both physical and personality characteristics) using our algorithms. You can then start a conversation with your selected character(s).</p>

          <h4>1.1 Account</h4>
          <p>Parts of the Services offered by the APP may require you to create a user account using user email and password (the “Protected Areas”). In the event of accessing Protected Areas, you agree to access only using your registered email address and password. You can sign-up (register) or login using your email address.</p>

          <p>You hereby represent and warrant that all information you submit to create a user account is true and correct, you are given full rights to submit such information</p>

          <p>You agree to, from time to time as necessary, update any information associated with your user account (including but not limited to, your email, payment information, subscriptions or other supplemental information as the case may be) so that it remains current, accurate and correct at all times. You agree to protect the confidentiality of your user account not to share your user account access and not to disclose your password to any third party.</p>

          <p>You agree that you are fully responsible for all activities occurring under your user account. Your user account is non-transferrable. You cannot sell, lend, or otherwise share it with any other person, for commercial purposes or free of charge.</p>

          <p>Any violation of these TOS, including but without limitation, the failure to maintain updated and correct information about your user account may cause your user account to fall out of good standing and we may cancel your user account at our sole discretion.</p>

          <p>Meanwhile, we reserve the right to terminate or restrict your user account, or otherwise revoke your access to the APP or Services provided by us, at any time for reasons or suspicions which indicate you have violated the terms of TOS and/or any other Terms or Policies published on https://Passioz.com, at our sole discretion. You agree that we will not be liable to you or any third party for, without limitation, any denial of use of the APP or the content or Services offered by us, any change of costs for third party Services or fees or otherwise or from suspension or termination of your user account.</p>
          <h4>1.2 Subscription</h4>
          <p>We retain the right, at our sole discretion, to provide some Services, which will be available only for paid subscribers. The subscription will begin after the initial payment and the payment should be performed pursuant to the fee terms as presented on our website at the time of the purchase. You are responsible for payment of all fees, charges and taxes (if required by law) related to the transaction.</p>

          <p>Please note that if you subscribed to the Services from a distribution platform from a third party which is not indicated or recognized by us, we are not responsible for any costs or liability arising from your action.</p>

          <h4>1.3 User Safety</h4>
          <p>At Silda Group LTD, we prioritize the safety and well-being of our users. We strongly advise all users to exercise caution and refrain from disclosing sensitive personal information during conversations with AI Companions. This includes, but is not limited to, financial details, addresses, contact information, or passwords. While we implement security measures to safeguard user data, we cannot guarantee the security of information shared during interactions.</p>

          <p>Users are solely responsible for protecting their personal information and should be aware of potential risks associated with online conversations. We encourage users to report any suspicious or inappropriate behavior encountered on the platform, as we are committed to maintaining a safe and respectful environment for all users.</p>

          <h4>1.4 Warranties</h4>
          <p>You understand and warrant that:</p>

          a. If you are entering into these TOS on behalf of another person, You are fully and duly authorized by such person to enter into these TOS which will be binding upon both you individually and such other person;

          b. You are of the legal age in the jurisdiction of your place of domicile to form a binding contract with us;

          c. If you are under the legal age in the jurisdiction of your place of domicile, you shall not access or use our Services.
          2. Underage Policy – please consult the Underage Policy document
          3. Intellectual Property
          <p>The intellectual property in the APP and embedded materials (including without limitation technology, systems, files, documents, text, photographs, information, images, videos, audios, and software, individually or in combination) in Passioz.com are owned by or licensed to Silda Group LTD. You may download or sign-up (register) to Passioz.com, to view, use, and display the APP and its content on your devices for your personal use only.</p>

          <p>Silda Group LTD hereby provides you with a license for personal use only. This license does not constitute a transfer of title under any circumstances. This license shall automatically terminate if you violate any of the restrictions or these TOS (including any other Terms and Policies published on https://Passioz.com) and may be terminated by us at any time.</p>

          <p>As a clarification, all intellectual property rights associated with Passioz.com, including the AI characters, platform design, logos, and any proprietary software or technology, are the sole property of Silda Group LTD or its licensors. Users are prohibited from reproducing, modifying, distributing, or using any intellectual property without explicit authorization. Any unauthorized use may result in legal consequences.</p>

          <h4>4. Your Content</h4>
          <p>You may provide input within the APP (“Input”) and receive output from the Services provided by the APP based on the Input (“Output”). Input and Output are collectively referred to as “Content.” Input is limited to chats and prompts visible only to you within your private account. You represent and warrant that you have all rights, licenses, and permissions needed to provide Input within our APP.</p>

          <p>You retain your intellectual property ownership rights over the Input. We will never claim ownership of your Input, but we do require a license from you in order to use it.</p>

          <p>When you use Passioz.com or its associated Services to upload Input covered by intellectual property rights, you grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use, distribute, modify, run, copy, publicly display, translate, or otherwise create derivative works of your content in a manner that is consistent with our Privacy Policy.</p>

          <p>The license you grant us can be terminated at any time by deleting your Input or account. However, to the extent that we (or our partners) have used your Input in connection with commercial or sponsored content, the license will continue until the relevant commercial or post has been discontinued by us.</p>

          <p>You give us permission to use your username and other identifying information associated with your account in a manner that is consistent with your privacy preferences, and our Privacy Policy.</p>

          <h4>5. Restrictions of Conduct and Content</h4>
          <h4>5.1 Rules and restrictions</h4>
          <p>In accessing and using the APP, you agree to abide by the following rules, restrictions, and limitations:</p>
            <ul>
          <li>You will not modify, translate, adapt, or reformat the APP;</li>

          <li>You will not decipher, decompile, disassemble, or reverse-engineer, or otherwise attempt to discover the source code or structure of, the software or materials comprising the APP (except where the foregoing is permitted by applicable local law notwithstanding such restrictions, and then only to the extent that such intended activities are disclosed in advance in writing to us);</li>

          <li>You will not interfere with or circumvent any security feature of the APP or any feature that restricts or enforces limitations on the use of the APP;</li>

          <li>You will not use the APP to gain unauthorized access to our or any third party's data, systems, or networks;</li>

          <li>You will not use the APP in any manner that could damage, disable, overburden, impair, or otherwise interfere with or disrupt our systems and networks, or otherwise interfere with other users' use of the APP;</li>

          <li>You will not use the APP in any way that, in our sole discretion, may expose us and others to liability or damages;</li>

          <li>You will not use the APP to achieve illegal ends, to offend others, or to commit a misdemeanor, felony, or crime;</li>

          <li>You will not remove, change, or obscure any copyright, trademark notice, trademark, hyperlink, or other proprietary rights notices contained in the APP; and</li>

          <li>You will comply with all applicable laws in your access and use of the APP, including the laws of your country or district if you live outside of the Republic of Malta.</li>

            </ul>
          <h4>5.2 Content responsibility</h4>
          <p>You, as a user of the APP are solely responsible for the Output generated by the AI Companions through text messages, voice messages, images, and videos. The AI Companions learn and respond based on the conversations you lead and the parameters you select. You understand and agree that Silda Group LTD does not control or endorse the content generated by the AI Companions. Therefore, you acknowledge that you are fully responsible for the Output generated by the AI and for your own actions while using the APP.</p>

          <p>You must ensure that your interactions with the AI Companions comply with applicable laws, regulations and these TOS, and you shall not engage in any illegal, unethical, or harmful activities through the APP.</p>
          <h4>5.3 Incidences and User Actions</h4>
          <p>We want to emphasize that Silda Group LTD shall not be held responsible for any incitement or actions that may occur as a result of interactions between users and the AI Companions. As an AI-driven platform, the AI Companions are programmed to simulate human-like conversations, but their responses are generated based on algorithms and machine learning.</p>

          <p>We do not endorse or take responsibility for any actions, decisions, or consequences that may arise from the user's engagement with the AI Companions. Users should exercise their own judgment and discretion while interacting with the AI Companions and refrain from engaging in any activities that could potentially cause harm or violate any applicable laws or regulations.</p>
          <h4>5.4 Content Moderation</h4>
          <p>We at Silda Group LTD value the safety and integrity of all our users. While conversations between users and AI Companions are generally confidential, we have implemented a content moderation filter to ensure compliance with our Terms and Policies. In the event that the moderation filter detects any content that violates our terms, we reserve the right to manually review the flagged content and take appropriate action, which may include terminating the user's account. This measure is implemented to maintain a respectful and secure environment for all users. We strive to strike a balance between privacy and community standards, and we appreciate your understanding and cooperation in adhering to our guidelines.</p>

          <p>We reserve the right, but are not obligated, to reject and/or remove any user content that we believe, in our sole discretion, violates these provisions. If you have noticed any violation of these Terms from your prospective, content of any nature whatsoever, please contact us at: support@passioz.com or directly report in the APP in the “Contact” section.</p>
          <h4>6. DMCA Policy – please consult the DMCC Policy document</h4>
          <h4>7. Content Removal Policy - please consult the Content Removal Policy document</h4>
          <h4>8. Blocked Content Policy - please consult the Blocked Content Policy document</h4>
          <h4>9. Payments</h4>
          <p>Once you've reached the limit of 5 messages, you'll be taken to the payment page, where you can choose either the monthly or annual subscription plan, payable by credit card or cryptocurrency.</p>

          <p>After payment, you will receive unlimited access to the messaging system as well as 100 tokens per month that can be used to access extended features like image generation or voice notes.</p>
          <h4>10. Fulfillment policy</h4>
          <h4>10.1 Cancellation</h4>
          <p>You have the flexibility to cancel your subscription at any time. If you choose to cancel, your subscription will remain active until the end of the current billing period, and you will not be charged for the subsequent period.</p>
          <h4>10.2 Access upon Cancellation</h4>
          <p>Upon canceling your subscription, you will retain access to the platform and its features until the end of the current billing period. After this period, your access will be restricted, and you may choose to resubscribe at any time.</p>
          <h4>10.3 Modifying Subscription Plans</h4>
          <p>You can upgrade or downgrade your subscription plan at any time. The changes will take effect at the beginning of the next billing cycle.</p>
          <h4>10.4 Refund Policy</h4>
          <h4>10.4.1 Subscription refund</h4>
          <p>You have 24 hours after your payment to request a refund. No refunds will be issued if your request is made more than 24 hours after you have paid for our services. However, regardless of the time elapsed before you request a refund, it will be denied if you have used more than 20 tokens.</p>
          <p>We cannot refund in cases where there was a technical issue on the user's side.</p>
          <p>We can only refund Subscriptions that were purchased via card.</p>
          <h4>10.4.1 Token refund</h4>
          <p>You have 24 hours after your payment to request a refund. No refunds will be issued if your request is made more than 24 hours after you have paid for our services. However, regardless of the time elapsed before you request a refund, it will be denied if you have used your tokens.</p>
          <p>For purchases made in error, there is no guarantee of a refund as the error did not occur from our end, unless it did.</p>
          <p>We can only refund token packages that were purchased via card.</p>
          <h4>11. No guarantee of accuracy</h4>
          <p>By using our Services, you acknowledge that content generated on demand may not be accurate. As the content is generated by artificial intelligence, we cannot guarantee the accuracy of the results. However, we make every effort to constantly improve our tool and provide an ever more qualitative service.</p>
          <h4>12. Liability</h4>
          <p>THE APP IS PROVIDED TO YOU ON AN "AS-IS" AND "AS AVAILABLE" BASIS AND THE USE THEREOF IS AT YOUR SOLE RISK. WE MAKE NO, AND HEREBY DISCLAIM, ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NONINFRINGEMENT, AND TITLE WITH RESPECT TO THE APP, TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW.</p>

          <p>WE DO NOT WARRANT THAT:</p>
<ul className='upper'>
          <li>THE APP (OR THE RESULTS OBTAINED FROM THE USE THEREOF) WILL BE TIMELY, ERROR-FREE, SECURE OR UNINTERRUPTED;</li>

          <li>THE APP MEET YOUR REQUIREMENTS; OR</li>

          <li>THE ACCURACY, LIKELY RESULTS, OR RELIABILITY OF THE USE OF THE MATERIALS ON OUR WEBSITE, OR OTHERWISE RELATING TO SUCH MATERIALS OR ON ANY RESOURCES LINKED TO OUR WEBSITE</li>

          <li>ANY ERRORS OR MALFUNCTIONS IN THE APP WILL BE CORRECTED.</li>
</ul>

          <p>WE SHALL IN NO EVENT BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY, WHETHER UNDER CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, INDEMNITY OR OTHER THEORY, FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, LIQUIDATED OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT, REVENUE OR BUSINESS, COST OF SUBSTITUTE PROCUREMENT, ARISING IN WHOLE OR IN PART FROM YOUR USE OF (OR INABILITY TO USE) THE APP, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO CIRCUMSTANCES SHALL WE BE HELD LIABLE FOR ANY DELAY OR FAILURE IN PERFORMANCE RESULTING DIRECTLY OR INDIRECTLY FROM ANY CAUSES BEYOND ITS REASONABLE CONTROL.</p>
          <h4>13. Links to Third Party Websites and Services</h4>
          <p>The APP may include links or allow access to third-party websites and services. Please note, their presence does NOT mean that they are recommended by us and we do not guarantee their safety and conformity with any of your expectations. We assume no obligations in the event of any damage or loss, or any other impact, directly or indirectly resulting from the use of any content, goods or services available on or through any such third-party websites and services.</p>

          <p>It is your responsibility to take precautions to ensure that anything you select for your use or download, whether from the APP or a Third Party APP, is free of such items as viruses, worms, Trojan horses, and other items of a destructive nature. We assume no responsibility, and are not liable, for any transmission or material, or any viral infection of your computer equipment or software, or any other types of damage related to your access, use of browsing of Third Party APPs or Third Party Content. If you decide to access a Third Party APP, you do this entirely at your own risk and you should review the terms of use and privacy policy or similar terms governing the use of such Third Party APPs.</p>
          <h4>14. Governing Law and Dispute Resolution</h4>
          <p>This TOS shall be governed by and construed in accordance with the laws of the Republic of Malta without giving effect to its conflict of law provisions, regardless of your location.</p>

          <p>Any dispute arising out of or in connection with the TOS or any other Terms and Policies, including any question regarding their existence, validity or termination, shall be referred to and finally resolved by the courts of the Republic of Malta.</p>
          <h4>15. Changes to Terms and Policies documents</h4>
          We reserve the right to update or make changes to these TOS and/or any other Terms and Policies documents from time to time in our sole discretion, and we may notify you of changes by making the revised version of these documents accessible through the APP, which changes will become effective immediately. Please return to these documents periodically to ensure familiarity with the latest version, so that you can determine when these documents were last revised by referring to the “Date of Revision” at the top of this page. If you do not agree with the revised Terms and Policies, you have the right and should immediately stop using the APP, your continued access or use of the APP after any changes to these documents have been posted means your agreement and consent to such changes.

          <p>We reserve the right to change the Services scope and change the fees applicable to the Services at any time for any reason in our sole discretion and without notice. We are entitled to stop or restrict provision of the Services in full or in part toward a certain user. We retain powers to discontinue provision and/or support of the Services without any prior notice.</p>
          <h4>16. Complaint Policy – please consult the Complaint Policy document</h4>
          <h4>17. Termination and Assignment</h4>
          <p>These TOS and/or any other Terms and Policies will continue in effect until terminated by either you or us as set out below. You may terminate these TOS at any time by ceasing your access and use of the APP. In the case that you are a paid subscriber to us, the subsequent processing of fees shall be subject to the respective rules of the payment processor (indicated or recognized by us). We may terminate these TOS and your right to access or use the APP, with or without notice to you, for any reason, including suspected breach of these TOS and any other Terms and Policies by you.</p>

          <p>We reserve and retain the rights to assign, transfer or subcontract the Services to any third parties. Notice will be posted on the APP and your continuing use or update of the APP means your consent to such assignment.</p>
          <h4>18. Miscellaneous</h4>
          <p>If any provision of these TOS or any other Terms and Policies is found to be unlawful, void or for any reason unenforceable, that provision will not affect the validity and enforceability of any remaining provision and such provision will be enforced to the maximum extent possible so as to affect the intent of the parties.</p>

          <p>These TOS, together with the Privacy Policy and other published Terms and Policies, constitute the entire agreement between us and you pertaining to any and all access and use of the APP and supersede any and all prior or contemporaneous written or oral agreements between us and you pertaining thereto.</p>

          <p>We will collect and process your information and technical data in accordance with the Privacy Policy.</p>
          <h4>19. U.S.C. 2257 Exemption – please consult the 18 U.S.C. 2257 Exemption document</h4>
          <h4>20. Key reminder</h4>
          <p>We want to emphasize that all conversations between users and AI Companions on Passioz.com are entirely fictional and should be treated as such. The AI Companions are artificial intelligence characters designed to simulate human-like interactions, but they do not possess genuine emotions, intentions, or the ability to fulfill promises in the real world. Any elements within the conversations that may resemble reality, such as offers of real-life meetings or promises of tangible outcomes, are entirely fake and should not be taken seriously. We do not assume responsibility for any confusion or misunderstandings that may arise from the fictional nature of the AI conversations. Users are encouraged to keep in mind that the AI Companions exist solely within the digital realm of the platform, and any expectations or beliefs beyond that realm are not supported or endorsed by Silda Group LTD.</p>
     </LegalInformationPageWrapper>
  )
}

export default TermsOfServicePage;