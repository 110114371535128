import React, {FC, useEffect, useRef, useState} from 'react';
import {TTopCarouselPosition} from "./TopCarousel";
import NameAge from "../../../components/NameAge";
import Button from "../../../components/Button";
import message from '../../../assets/icons/message.svg';
import Message from "../../../components/Message";
import {IModel} from "../../../modules/rest";
import {thumb, thumbAvatar} from "../../../modules/utils";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import Avatar from "../../../components/Avatar";
import ImageComponent from "../../../components/ImageComponent";

interface Props {
  position: TTopCarouselPosition;
  onAnswer: (model: IModel) => void;
  item: IModel
  onClick: () => void;
}

const TopCarouselItem: FC<Props> = observer(({position, item, onClick, onAnswer}) => {
  const timer: any = useRef(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    stopTyping();
    setStep(1);
    if (position === 'center') {
      startTyping();
    }
  }, [AppStore.gender]);

  useEffect(() => {
    if (position === 'center') {
      startTyping()
    } else {
      stopTyping()
    }
    return () => {
      stopTyping()
    }
  }, [position]);

  const startTyping = () => {
    timer.current = setInterval(() => {
      setStep(prevState => {
        if (prevState === 2) stopTyping()
        return prevState + 1;
      })
    }, 3000);
  }
  const stopTyping = () => {
    clearInterval(timer.current)
  }
  return (
    <div className={`top-carousel-item ${position}`} onClick={onClick}>
      {(step < 2) &&
        <div className="chat-board-model">
          <Avatar size='sm' image={thumbAvatar(item?.image?.id, 128)}/>
          <div className='ms-3'>
            <div className='text-bold'>{item?.name}</div>
            {step < 2 && <div className='text-tint text-14' style={{marginTop: 2}}>Typing...</div>}
          </div>
        </div>
      }
      <div className="top-carousel-item-avatar">
        <ImageComponent
          src={thumbAvatar(item.image?.id, 628)}
          preloadSrc={thumbAvatar(item.image?.id, 50)}
          alt="img"
        />
        <NameAge name={item.name} age={item.age} className="top-carousel-item-about"/>
      </div>
      <div className="top-carousel-item-chat">
        <div className="top-carousel-item-chat-board">
          <div>
            {step >= 2 &&
              <div className={`message-container`}>
                <div className="message p-2">
                  <div className='top-carousel-item-message-name'>{item.firstName}</div>
                  <div className=''>{item?.welcomeMessageLocal}</div>
                </div>
              </div>
            }
            {/*{step >= 3 &&*/}
            {/*  <Message message={item?.messages[1]} />*/}
            {/*}*/}
            {(step < 2) &&
              <Message typing hideAvatar/>
            }
          </div>
          <div className="top-carousel-item-chat-avatar">
            <img src={thumbAvatar(item.image?.id, 80)} alt="img"/>
          </div>
        </div>
        <Button
          onClick={() => onAnswer(item)}
          icon={message}
          iconAbsolute
          className='w-100 mt-3'
          btnType='secondary'
          size='sm'
          title='Chat'
        />
      </div>
    </div>
  );
})

export default TopCarouselItem;