import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";

interface Props {
}

const ComplainPolicy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='COMPLAIN_POLICY'>
          <p>At Silda Group LTD, we value our users and strive to provide a positive experience with Passioz.com (the “APP”). We understand that concerns or complaints may arise from time to time, and we are committed to addressing them promptly and effectively. This Complaint Policy outlines the process for users to file complaints regarding any issues encountered while using our platform.</p>
          <h4>1. Customer Support</h4>
          <p>We have a dedicated support team to assist our users with any concerns or complaints.</p>

          <p>Our support team is committed to provide prompt and effective assistance. Users can contact our support team if they come across any issues or have inquiries regarding our APP or services.</p>

          <p>All inquiries are managed by our support team with professionalism, confidentiality, and impartiality. We are committed to addressing our users’ concerns promptly and to the best of our abilities.</p>
          <h4>2. Submitting a Complaint</h4>
          <p>Users who wish to file a complaint can do so by contacting our customer support team through support@passioz.com or directly report in the APP in the “Contact” section.</p>
          <h4>3. Information to Include in the Complaint</h4>
          <p>When submitting a complaint, users are encouraged to provide the following details to help us investigate and address the issue promptly:</p>
<ul>
          <li>User's full name and e-mail;</li>

          <li>Description of the complaint, including relevant details such as the date and time of the incident;</li>

          <li>Any supporting documentation or screenshots, if applicable.</li>
</ul>
          <h4>4. Acknowledgment of Complaint</h4>
          <p>Upon receiving a complaint, our customer support team will acknowledge the receipt within 24 hours via e-mail.</p>
          <h4>5. Investigation and Resolution</h4>
          <p>We will conduct a thorough investigation into each complaint to understand the nature of the issue. Our goal is to provide a resolution within a reasonable timeframe. Depending on the complexity of the complaint, some cases my require additional time to conduct a comprehensive investigation. Users will be kept informed of the progress and expected resolution timeline.</p>
          <h4>6. Feedback and Follow-Up</h4>
          <p>Once the complaint has been addressed, users will receive feedback regarding the outcome of the investigation and any actions taken. We may also seek user feedback on the resolution process to continuously improve our services.</p>
          <h4>7. Escalation</h4>
          <p>If a user is dissatisfied with the resolution provided, he/she may request further escalation. In this case users can notify our support team within a reasonable timeframe, providing clear reasons for their dissatisfaction with the initial resolution.</p>

          <p>The case will be reviewed by individuals or teams not initially involved in the complaint resolution process. They will re-assess the complaint and reconsider the previous decision. The user will be notified of the outcome of the escalation within a reasonable timeframe.</p>
    </LegalInformationPageWrapper>
  );
}

export default ComplainPolicy;