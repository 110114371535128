import React, {FC, useState} from 'react';
import OutsideClick from "../../../../components/OutsideClick";
import more from '../../../../assets/icons/more.svg';
import broom from '../../../../assets/icons/broom.svg';
import trash from '../../../../assets/icons/trash.svg';
import user_svg from '../../../../assets/icons/user.svg';
import {ReactSVG} from "react-svg";
import {toast} from "react-toastify";
import {API} from "../../../../modules/api";
import ChatStore from "../../../../store/ChatStore";
import {useNavigate} from "react-router-dom";
import {runInAction} from "mobx";
import {observer} from "mobx-react";
import {confirmDialog} from "../../../../modals";
import {useTranslation} from "react-i18next";

interface Props {
  onProfileClick?: () => void;
  fromModelProfile?: boolean;
}

const ChatSettings: FC<Props> = observer(({onProfileClick, fromModelProfile}) => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false);

  const handleClean = async () => {
    const confirmed = await confirmDialog(t('Confirm_message_clear_chat'), {
      title: t('CLEAR_CHAT') || '',
      icon: broom,
      confirmText: 'CLEAR',
    })
    if (confirmed) {
      try {
        await API.Conversations.resetConversation(ChatStore.activeChat?.id!);
        await ChatStore.getList();
        await ChatStore.getMessages(ChatStore.activeChat?.id!);
      } catch (e: any) {
        toast.error(e);
      }
    }
  }

  const handleRemove = async () => {
    const confirmed = await confirmDialog(t('Confirm_message_delete_room'), {
      title: t('DELETE_CHAT')!,
      icon: trash,
      confirmText: 'DELETE',
    })
    if (confirmed) {
      try {
        await API.Conversations.deleteConversation(ChatStore.activeChat?.id!);
        await ChatStore.getList();
        const lastChat = ChatStore.chats[0];
        if (lastChat && !fromModelProfile) {
          await ChatStore.getChat(lastChat.id);
          navigate(`/chat/${lastChat.id}`);
        } else {
          runInAction(() => {
            ChatStore.messages = [];
            ChatStore.activeChat = undefined;
          })
          navigate('/chat');
        }
      } catch (e: any) {
        toast.error(e);
      }
    }

  }


  const toggleVisible = (e: any) => {
    e.stopPropagation()
    setVisible(prevState => !prevState);
  }

  return (
    <div className='chat-top-controls'>
      {onProfileClick
        ?
        <div className='chat-top-controls-profile' onClick={onProfileClick}>
          <ReactSVG src={user_svg} className='react-icon' onClick={toggleVisible}/>
          <div className='text-bold-14 ps-2 ms-1'>{t('VIEW_PROFILE')}</div>
        </div>
        :
        null
      }
      <OutsideClick outsideClickEvent={() => setVisible(false)}
                    className={`chat-settings popover${visible ? ' visible' : ''}`}>
        <ReactSVG src={more} className='react-icon chat-settings-btn' onClick={toggleVisible}/>
        <div className="popover-panel-container">
          <div className="popover-panel p-2  text-bold-14 text-nowrap">
            <div className='d-flex align-items-center p-2 cursor-pointer' onClick={handleClean}>
              <ReactSVG src={broom} className='react-icon me-2'/>
              <span>{t('CLEAR_CHAT')}</span>
            </div>
            <div className='d-flex align-items-center p-2 cursor-pointer' onClick={handleRemove}>
              <ReactSVG src={trash} className='react-icon me-2'/>
              <span className='text-danger'>{t('DELETE_CHAT')}</span>
            </div>
          </div>
        </div>
      </OutsideClick>
    </div>
  );
})

export default ChatSettings;