import {useLayoutEffect, useRef, useState} from 'react';
import {ETaskStatus, IGetTasksRequest, ITask, TIdentifier} from "../modules/rest";
import {API} from "../modules/api";
import {toast} from "react-toastify";
import AppStore from "../store/AppStore";

interface Props {
  fetch?: () => void;
  type: IGetTasksRequest["type"];
}

export const useTask = <T>({fetch, type}: Props): [task?: T, generating?: boolean|'finished'] => {
  const ping: any = useRef(null);
  const [generatingAILoading, setGeneratingAILoading] = useState<boolean|'finished'>(false);
  const [task, setTask] = useState<T>();

  useLayoutEffect(() => {
    if (!AppStore.ready) return;
    getTasks();
    return () => {
      clearTimeout(ping.current);
    }
  }, [AppStore.ready]);

  const getTasks = async () => {
    try {
      const tasks = await API.Tasks.getList({type, showTerminal: false});
      if (tasks.length) {
        pingTask(tasks[0].id);
      }
    } catch (e) {

    }
  }

  const pingTask = async (id: TIdentifier, init?: boolean) => {
    setGeneratingAILoading(true);
    try {
      const task: ITask = await API.Tasks.getTask(id);
      setTask(task as T);
      if (task.status === ETaskStatus.Completed) {
        if (init) return setGeneratingAILoading(false);
        clearTimeout(ping.current);
        setGeneratingAILoading('finished');
        setTimeout(async () => {
          if (fetch) await fetch();
          setGeneratingAILoading(false);
          getTasks();
        }, 3000);
        // setTask(undefined);
        AppStore.getUser();
      } else if (task.status === ETaskStatus.Failed || task.status === ETaskStatus.Cancelled) {
        if (task.error) toast.error(task.error);
        setGeneratingAILoading(false);
        clearTimeout(ping.current);
        getTasks();
      } else {
        ping.current = setTimeout(() => pingTask(task.id!), 1000);
      }
    } catch (e: any) {
      // toast.error(e);
      setGeneratingAILoading(false);
      clearTimeout(ping.current);
    } finally {

    }
  }

  return [task, generatingAILoading]


}
