import React, {FC} from 'react';
import Button from "../../../components/Button";
import ModelCard from "../../../components/ModelCard";
import ActiveElement from "../../../components/ActiveElement";
import {EModelStyle, IModel} from "../../../modules/rest";
import {observer} from "mobx-react";
import HomeStore from "../../../store/HomeStore";
import {useTranslation} from "react-i18next";
import HorizontalScroll from "../../../components/HorizontalScroll";

interface Props {
  onAnswer: (model: IModel) => void;
  className?: string;
  title?: string;
  titleClassName?: string;
}

const HomeList: FC<Props> = observer(({onAnswer, className, title, titleClassName}) => {
  const {t} = useTranslation();
  return (
    <section className={`home-models ${className || ''}`}>
      <div className="container px-4">
        <h2 className={`text-center ${titleClassName || ''}`}>
          <span className='text-tint me-2 me-md-3'>{t(title || 'EXPLORE')}</span>{t('AI_CHARACTERS')}
        </h2>
        {/*<HorizontalScroll className='character-category'>*/}
        {/*  <Button*/}
        {/*    className={!HomeStore.style ? 'active' : ''}*/}
        {/*    btnType='third'*/}
        {/*    title='ALL_MODELS'*/}
        {/*    onClick={() => HomeStore.onCategoryChange()}*/}
        {/*  />*/}
        {/*  {Object.values(EModelStyle).map(item => (*/}
        {/*    <Button*/}
        {/*      key={item}*/}
        {/*      className={item === HomeStore.style ? 'active' : ''}*/}
        {/*      btnType='third'*/}
        {/*      title={item.toUpperCase()}*/}
        {/*      onClick={() => HomeStore.onCategoryChange(item)}*/}
        {/*    />*/}
        {/*  ))}*/}

        {/*</HorizontalScroll>*/}
        <div className="home-models-list">
          {HomeStore.models.map((item, i) => (
            <ActiveElement key={i}>
              <ModelCard
                model={item}
                category={item.style}
                onClick={() => onAnswer(item)}
                chatBtn
                imageSize={900}
              />
            </ActiveElement>
          ))}
        </div>
      </div>
    </section>
  );
})

export default HomeList;