import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
}

const HomeFooterContacts: FC<Props> = ({className}) => {
  const {t} = useTranslation();

  return (
    <div className={`home-footer-contacts ${className || ''}`}>
      <div className='text-muted-14'>{t('CONTACTS')}</div>
      <div className='text-bold text-muted mt-3'>
        Silda Group LTD <br/>
        85 Great Portland Street, First Floor, London, United Kingdom, W1W 7LT
      </div>
    </div>
  );
}

export default HomeFooterContacts;