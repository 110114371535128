import React, {FC} from 'react';
import Button from "../../../components/Button";
import magic_wand from '../../../assets/icons/magic_wand.svg';
import diamond from '../../../assets/icons/diamond.svg';
import TopCarousel from "./TopCarousel";
import GenderSelect from "../../../components/GenderSelect";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import { IModel} from "../../../modules/rest";
import HomeTimer from "./HomeTimer";
import {useTranslation} from "react-i18next";


interface Props {
  onAnswer: (model: IModel) => void;
}

const TopSection: FC<Props> = observer(({onAnswer}) => {
  const {t} = useTranslation();
  return (
    <section className='mt-0 section-bg home-top-section'>
      <div className="container px-4">
        <div className="home-top-container">
          <div className='d-flex justify-content-center'>
          <GenderSelect onChange={AppStore.changeGender} gender={AppStore.gender}/>
          </div>
          <TopCarousel onAnswer={onAnswer}/>
        </div>
        <div className='home-top-footer'>
          <div className='text-bold me-2 pe-1'>{t('FIRST_SUB')}</div>
          <Button
            href={'/premium'}
            icon={diamond}
            btnType='secondary'
            size='sm'
            title='UP_OFF_75'
          />
          <HomeTimer/>
        </div>
      </div>
    </section>
  );
})

export default TopSection;