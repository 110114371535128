import React, {FC} from 'react';

interface Props {
  name: string;
  age: number;
  className?: string;
}

const NameAge: FC<Props> = ({name, age, className}) => {
  return (
    <h4 className={`name-age ${className || ''}`}>{name} <span>{age}</span></h4>
  );
}

export default NameAge;