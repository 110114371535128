import React, {FC } from 'react';
import NameAge from "../NameAge";
import Button from "../Button";
import message from "../../assets/icons/message.svg";
import {IModel} from "../../modules/rest";
import {thumb} from "../../modules/utils";
import ImageComponent from "../ImageComponent";
import {useTranslation} from "react-i18next";
// const {t} = useTranslation();

interface Props {
  className?: string;
  chatBtn?: boolean;
  onClick?: () => void;
  hideAbout?: boolean;
  active?: boolean;
  category?: string;
  model?: IModel;
  imageSize?: number;
}



const ModelCard: FC<Props> = ({
                                className,
                                chatBtn,
                                onClick,
                                hideAbout,
                                active,
                                category,
                                model,
                                imageSize,
                              }) => {
  const {t} = useTranslation();

  if (!model) return <div className={`model-card${active ? ' active' : ''} ${className || ''}`} onClick={onClick}></div>

  return (
    <div className={`model-card${active ? ' active' : ''} ${className || ''}`} onClick={onClick}>
      {category ?
        <div className="model-card-category"><span className='letter-uppercase'>{t(category.toUpperCase())}</span></div> : null}
      {model.image ?
        <ImageComponent
          src={thumb(model?.image?.id, imageSize || 800)}
          preloadSrc={thumb(model?.image?.id, 50)}
          alt={'model_' + model.id}
        />
        : null}
      {!hideAbout &&
        <>
          <div className="model-card-bg"/>
          <div className="model-card-about">
            <NameAge name={model.firstName} age={model.age}/>
            {model.aboutLocal ? <div className='text-truncate-2 text-14 mt-3'>{model.aboutLocal}</div> : null}
            {chatBtn
              ?
              <Button
                icon={message}
                iconAbsolute
                className='w-100 mt-4 model-card-chat-btn'
                size='sm'
                title='CHAT'
              />
              :
              null
            }

          </div>
        </>
      }
      {chatBtn
        ?
        <Button
          circle
          icon={message}
          className='model-card-chat-btn-mobile'
          size='sm'
        />
        :
        null
      }
    </div>

  );
}

export default ModelCard;