import React, {FC, useEffect, useState} from 'react';
import {Input, InputPassword} from "../../../components/FormControls";
import email_svg from "../../../assets/icons/email.svg";
import Button from "../../../components/Button";
import signIn_svg from "../../../assets/icons/signIn.svg";
import google_svg from "../../../assets/icons/google.svg";
import {useGoogleLogin} from "@react-oauth/google";
import {EFieldGroup, ILoginRequest, IUser} from "../../../modules/rest";
import {API} from "../../../modules/api";
import AppStore from "../../../store/AppStore";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import ChatStore from "../../../store/ChatStore";
import {useTranslation} from "react-i18next";

interface Props {
  onFinish: (status: boolean|number) => void;
  onTypeChange: (type: 'login'|'register'|'recovery') => void;
  authType: 'login'|'register'|'recovery'
}

const AuthSign: FC<Props> = observer(({onFinish, authType, onTypeChange}) => {
  const {t} = useTranslation();

  const [user, setUser] = useState<ILoginRequest>({email: '', password: ''});
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const queryEmail = query.get('email');
  useEffect(() => {
    if (queryEmail) {
      setUser(prevState => ({...prevState, email: queryEmail}));
    }
  }, []);

  const handleChange = (key: keyof ILoginRequest) => (e: any) => {
    setUser(prevState => ({...prevState, [key]: e.target.value}))
  }

  const onSuccess = async (res: { token: string; user: IUser }) => {
    window.localStorage.setItem('token', res.token);
    API.setToken(res.token);
    AppStore.login(res.user);
    let conversationId;
    if (ChatStore.activeChat) {
      const res = await API.Models.startConversation(ChatStore.activeChat?.model?.id!);
      await ChatStore.getList(true);
      await ChatStore.getChat(res.id);
      localStorage.removeItem('conversation');
      conversationId = res.id;
    }
    onFinish(conversationId || true);
  }


  const handleLogin = async (e: any) => {
    e.preventDefault();
    if(loading) return;
    try {
      setLoading(true);
      let res;
      if (authType === 'register') {
        const utm: any = localStorage.getItem('utm') || {};
        res = await API.Users.register(
          {...user, utm: JSON.parse(utm)},
          [EFieldGroup.UserSubscription, EFieldGroup.UserGender]
        );
      } else {
        res = await API.Users.login(user, [EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      }
      onSuccess(res);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  const authTitle = authType === 'register' ? 'CREATE_ACC' : 'SIGN_IN';

  return (
    <>
      <h4 className='mb-4'>{t(authTitle)}</h4>
      <form onSubmit={handleLogin}>
        <Input
          required
          icon={email_svg}
          label='EMAIL'
          className='mb-3'
          placeholder='E-MAIL'
          value={user.email}
          type='email'
          onChange={handleChange('email')}
        />
        <InputPassword
          onForgotPassword={authType === 'login' ? () => onTypeChange('recovery') : undefined}
          required
          value={user.password}
          onChange={handleChange('password')}
        />
        <Button
          className='w-100 mt-4'
          icon={authType !== 'register' && signIn_svg}
          iconAbsolute
          loading={loading}
          title={authTitle}
          type='submit'
        />
        <div className='mt-4 text-dark-14 text-center'>
          {authType === 'register' &&
            <span>{t('ALREADY_ACC')} <span className='text-tint-active'
                                                     onClick={() => onTypeChange('login')}>{t('SIGN_IN')}</span></span>
          }
          {authType === 'login' &&
            <span>{t('NO_ACC')} <span className='text-tint-active'
                                                   onClick={() => onTypeChange('register')}>{t('SIGN_UP')}</span></span>
          }
        </div>
      </form>
    </>
  );
})

export default AuthSign;