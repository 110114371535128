import {useEffect} from 'react';
import {useLocation} from "react-router-dom";


const useUtm = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const utm = {
      utm_source: query.get('utm_source') || null,
      utm_medium: query.get('utm_medium') || null,
      utm_campaign: query.get('utm_campaign') || null,
      utm_content: query.get('utm_content') || null,
      utm_term: query.get('utm_term') || null,
      esub: query.get('esub') || null,
      pub_id: query.get('pub_id') || null,
    }
    localStorage.setItem('utm', JSON.stringify(utm));
  }, []);
}

export default useUtm;