import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";

interface Props {
}

const USCExemption: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='USC_EXEMPTION'>
          <p>We operate as a purely AI-generated content platform where no real individuals are portrayed or engaged in any content creation. Every piece of content accessible on Passioz.com (the”APP”) is exclusively generated through artificial intelligence (AI) technology. This method eliminates the participation of real human beings in the creation of images, videos, or any other material available on our APP.</p>

          <p>The Federal Labeling and Record-Keeping Law is therefore not applicable.</p>

          <p>If you have any questions or require further clarification regarding 18 U.S.C. 2257 Exemption, please contact us at: support@passioz.com or directly report in the APP in the “Contact” section.</p>
    </LegalInformationPageWrapper>
  );
}

export default USCExemption;