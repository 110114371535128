import React, {FC, useLayoutEffect} from 'react';
import {observer} from "mobx-react";
import ChatsList from "./components/ChatsList";
import Chat from "./components/Chat";
import AppStore from "../../store/AppStore";
import {useNavigate, useParams} from "react-router-dom";
import ChatStore from "../../store/ChatStore";
import HomeList from "../HomePage/components/HomeList";
import {API} from "../../modules/api";
import {IModel} from "../../modules/rest";
import {runInAction} from "mobx";
import {ModelProfile} from "../ModelProfilePage";
import {toast} from "react-toastify";
import {IConversationModel, setConversationModel} from "../HomePage/modules/utils";
import {HeaderSecondary} from "../../containers/Header";
import {useTranslation} from "react-i18next";

interface Props {
  chatPageType: 'list'|'chat'|'profile'
}

const ChatPage: FC<Props> = observer(({chatPageType}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (AppStore.ready) {
      if (AppStore.user?.id) {
        init();
      } else {
        const conversation = localStorage.getItem('conversation');
        if (conversation) {
          handleConversation(JSON.parse(conversation));
        }
        runInAction(() => {
          ChatStore.ready = true;
        })
      }
    }
    return () => {
      runInAction(() => {
        // ChatStore.activeChat = undefined;
        // ChatStore.messages = [];
      })
    }
  }, [AppStore.ready]);

  const init = async () => {
    try {
      ChatStore.getList();
    } catch (e) {

    }
  }

  const handleAnswer = async (model: IModel) => {
    if (!AppStore.user?.id) {
      const conversation = setConversationModel(model);
      handleConversation(conversation);
    } else {
      try {
        await API.Models.startConversation(model.id);
        await init();
      } catch (e: any) {
        toast.error(e)
      }
    }
  }

  const handleConversation = ({ chats, messages, activeChat }: IConversationModel) => {
    runInAction(() => {
      ChatStore.chats = chats;
      ChatStore.messages = messages;
      ChatStore.activeChat = activeChat;
      navigate(`/chat/${activeChat?.model?.name?.replaceAll(' ', '_')}`,{replace: true});
    });
  };

  if (!ChatStore.ready) return null;

  if (!ChatStore.chats?.length) return (
    <div>
      <HeaderSecondary onClick={() => AppStore.toggleVisibleAside()} title='CHOOSE_AI'/>
      <HomeList onAnswer={handleAnswer} className='mt-0 mb-3 mb-md-4' title='CHOOSE' titleClassName={'page-title'}/>
    </div>
  )


  return (
    <div className={`chat-page chat-page-type-${chatPageType}`}>
      <ChatsList className='chats-section' handleConversation={handleConversation}/>
      <Chat/>
      <div className={`model-profile chats-section`}>
        <h6 className='chats-section-title'><span>{t('PROFILE')}</span></h6>
        <div className={'chats-side-list-container'}>
          <div className={'chats-side-list'}>
            {ChatStore.activeChat?.model?.id
              ?
              <ModelProfile model={ChatStore.activeChat?.model}/>
              :
              null
            }
          </div>
        </div>
      </div>
    </div>
  );
})

export default ChatPage;