import React, {FC, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import PremiumBenefits from "./components/PremiumBenefits";
import {ReactSVG} from "react-svg";
import shield from "../../../../assets/icons/shield.svg";
import {IAddCreditsRequest, ICreateSubscriptionRequest} from "../../../../modules/rest";
import {
  benefitsCredits,
  benefitsSubscription,
  creditsData,
  ICreditsDataItem,
  ISubscriptionDataItem,
  subscriptionsData
} from "./modules/utils";
import SubscriptionsPlanItem from "./components/SubscriptionsPlanItem";
import CreditsPlanItem from "./components/CreditsPlanItem";
import {HeaderSecondary} from "../../../../containers/Header";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button";

const paymentData: any = {
  credits: {
    title: 'EXCLUSIVE_PACKAGE',
    subTitle: 'EXCLUSIVE_PACKAGE_TITLE',
    benefitsTitle: 'TOKEN_BENEFITS',
    benefits: benefitsCredits,
    itemsData: creditsData
  },
  subscription: {
    title: 'CHOOSE_PLAN',
    subTitle: 'PREMIUM_TITLE',
    benefitsTitle: 'TOKEN_BENEFITS',
    benefits: benefitsSubscription,
    itemsData: subscriptionsData
  },
}


interface Props {
}

const PremiumPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {paymentType} = useParams();
  const [paymentPlan, setPaymentPlan] = useState<ICreateSubscriptionRequest['period']|IAddCreditsRequest['credits']>(paymentType === 'subscription' ? '1' : '550');
  const data = paymentData[paymentType!];

  useEffect(() => {
    if (!data) navigate('/', {replace: true});
  }, []);

  if (!data) return null;
  return (
    <>
      <HeaderSecondary title={data.title} coins onClick={() => navigate(-1)} back/>
      <div className='container premium-page'>
        <h2 className='page-title'>{t(data.title)}</h2>
        {paymentType === 'subscription' &&
          <div className='text-muted text-center d-none d-md-block mt-3 mb-4'>{t('PREMIUM_TEXT')}</div>}
        <div className="premium-container">
          <h6 className='text-center' dangerouslySetInnerHTML={{__html: t(data.subTitle) || ''}}/>
          {paymentType === 'subscription' &&
            <div className='text-muted text-center  d-block d-md-none mb-3'>{t('PREMIUM_TEXT')}</div>}
          <PremiumBenefits title={data.benefitsTitle} data={data.benefits}/>
          <div className="row g-2 g-md-4 pt-4 w-100">
            {Object.entries(data?.itemsData)?.map(([value, item], i) => (
              paymentType === 'subscription'
                ?
                <SubscriptionsPlanItem
                  onClick={() => setPaymentPlan(value as ICreateSubscriptionRequest['period'])}
                  data={item as ISubscriptionDataItem}
                  period={value}
                  active={value === paymentPlan}
                  key={i}
                />
                :
                <CreditsPlanItem
                  onClick={() => setPaymentPlan(value as IAddCreditsRequest['credits'])}
                  data={item as ICreditsDataItem}
                  credits={value}
                  active={value === paymentPlan}
                  key={i}
                />
            ))}
          </div>
          {paymentType === 'subscription' &&
            <div className='text-dark-14 d-flex align-items-center justify-content-center mt-3'>
              <ReactSVG src={shield} className='react-icon me-1'/>
              {t('NO_ADULT')}
            </div>
          }
          <Button
            className='modal-footer w-100'
            href={paymentPlan}
            // onClick={() => onClick(EPaymentMethod.PayCly)}
            // icon={card}
            title='PAY'
            style={{maxWidth: 344}}
          />
          {paymentType === 'subscription' &&
            <div className='text-dark-14 mt-3 text-center'>
              {subscriptionsData[paymentPlan as ICreateSubscriptionRequest['period']]?.planText &&
                <div>{t(subscriptionsData[paymentPlan as ICreateSubscriptionRequest['period']]?.planText || '')}</div>}
              <div>{t('CANCEL_ANYTIME')}</div>
            </div>
          }
        </div>
      </div>
    </>
  )

})

export default PremiumPage;