import React, {FC, useEffect} from 'react';
import AppStore from "../../store/AppStore";
import {observer} from "mobx-react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import PremiumPage from "./pages/PremiumPage";
import PaymentMethodPage from "./pages/PaymentMethodPage/PaymentMethodPage";
import BillingAddressPage from "./pages/BillingAddressPage";

interface Props {
}

const PaymentPage: FC<Props> = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    if ((AppStore.ready && !AppStore.user?.id))
      navigate('/', {replace: true})
  }, [AppStore.ready]);

  if (!AppStore.user?.id) return null;
  return (
    <Routes>
      <Route path=':paymentType' element={<PremiumPage/>}/>
      <Route path=':paymentType/:paymentPlan' element={<PaymentMethodPage/>}/>
      <Route path=':paymentType/:paymentPlan/:paymentMethodId' element={<BillingAddressPage/>}/>
      <Route path='' element={<Navigate to="/premium/subscription" replace/>}/>
    </Routes>
  );
})

export default PaymentPage;