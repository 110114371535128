import React, {FC, useLayoutEffect, useState} from 'react';
import {SearchInput} from "../../../components/FormControls";
import Button from "../../../components/Button";
import chevron_active from '../../../assets/icons/chevron_active.svg';
import {observer} from "mobx-react";
import Avatar from "../../../components/Avatar";
import {thumbAvatar} from "../../../modules/utils";
import OutsideClick from "../../../components/OutsideClick";
import {ReactSVG} from "react-svg";
import {IGetPhotosRequest, IModel} from "../../../modules/rest";
import {API} from "../../../modules/api";
import HorizontalScroll from "../../../components/HorizontalScroll";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface Props {
  activeModelId?: null|number;
  onChange: (req: IGetPhotosRequest) => void;
}

const GalleryNav: FC<Props> = observer(({activeModelId, onChange}) => {
  const {t} = useTranslation();
  const [models, setModels] = useState<IModel[]>();
  const [modelsVisible, setModelsVisible] = useState(false);


  useLayoutEffect(() => {
    fetchModels()
  }, []);

  const fetchModels = async () => {
    try {
      const res = await API.Gallery.getModels();
      setModels(res);
    } catch (e: any) {
      toast.error(e)
    }
  }

  const toggleModelsVisible = (e: any) => {
    e.stopPropagation();
    if (activeModelId) return onChange({model: null});
    setModelsVisible(prevState => !prevState);
  }

  const onSelect = (id: null|number) => () => {
    onChange({model: id});
    setModelsVisible(false);
  }

  return (
    <div className='row g-4 gallery-nav'>
      {/*<div className="col-3">*/}
      {/*  <SearchInput onSearch={(query) => onChange({query})}/>*/}
      {/*</div>*/}
      <div className='position-relative col-12'>
        <HorizontalScroll className='gallery-nav-list-container' trigger={models?.length}>
          <OutsideClick
            outsideClickEvent={() => setModelsVisible(false)}
            className={`gallery-models-all${modelsVisible ? ' visible' : ''}`}>
            <Button className={!activeModelId ? 'active' : ''} btnType='third' onClick={toggleModelsVisible}>
              <div className=''>{t('ALL_MODELS')}</div>
              {!activeModelId && <ReactSVG src={chevron_active} className='react-icon ms-2 ps-1'/>}
            </Button>
          </OutsideClick>
          {models?.map(model => (
            <Button
              key={model.id}
              className={`gallery-models-item${activeModelId === model?.id ? ' active' : ''}`}
              btnType='third'
              onClick={onSelect(model.id)}
            >
              <Avatar image={thumbAvatar(model.image?.id, 128)}/>
              <div className='ms-2'>{model.name}</div>
            </Button>

          ))}
        </HorizontalScroll>
        {modelsVisible && <div className='gallery-models-hidden'>
          {models?.map(model => (
            <div
              key={model.id}
              className={`gallery-models-hidden-item${activeModelId === model?.id ? ' active' : ''}`}
              onClick={onSelect(model.id)}
            >{model.name}</div>
          ))}
        </div>}
      </div>
    </div>
  );
})

export default GalleryNav;