import React, {FC, useLayoutEffect, useState} from 'react';
import Button from "../../components/Button";
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import Avatar from "../../components/Avatar";
import diamond from '../../assets/icons/diamond.svg';
import burger from '../../assets/icons/burger.svg';
import GenderSelect from "../../components/GenderSelect";
import {useLocation, useNavigate} from "react-router-dom";
import OutsideClick from "../../components/OutsideClick";
import {ReactSVG} from "react-svg";
import settings from "../../assets/icons/settings.svg";
import logout from "../../assets/icons/logout.svg";
import plus_active from "../../assets/icons/plus_active.svg";
import chevron_down from "../../assets/icons/chevron_down.svg";
import {ESubscriptionStatus} from "../../modules/rest";
import {thumb} from "../../modules/utils";
import useAuth from "../../hooks/useAuth";
import {useTranslation} from "react-i18next";



interface Props {
  className?: string;
}

const Header: FC<Props> = observer(({className}) => {
  const {t, } = useTranslation();
  const [onAuthClick] = useAuth()
  const navigate = useNavigate();
  const location = useLocation();
  const [genderVisible, setGenderVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);

  useLayoutEffect(() => {
    if (location.pathname === '/') {
      setGenderVisible(false)
      const onScroll = () => window.scrollY > 150 ? setGenderVisible(true) : setGenderVisible(false)
      window.addEventListener('scroll', onScroll, {passive: true});
      return () => window.removeEventListener('scroll', onScroll);
    }

  }, [location.pathname]);

  const toggleProfileVisible = (e: any) => {
    e.stopPropagation();
    if (window.innerWidth < 1200) {
      navigate('/settings');
    } else {
      setProfileVisible(prevState => !prevState);
    }

  }

  const handleSettings = () => {
    setProfileVisible(false)
    navigate('/settings')
  }
  const handleLogout = () => {
    setProfileVisible(false);
    AppStore.logout();
    navigate('/')
  }


  if (!AppStore.ready) return null;
  return (
    <>
      <header className={className || ''}>
        <div className='header'>
          <Button
            onClick={() => AppStore.toggleVisibleAside(true)}
            size='sm'
            circle
            btnType='secondary'
            contentClassName='p-0'
            className={'header-burger'}
            icon={burger}
          />
          <div className='d-flex align-items-center h-100'>
            <div>
              <GenderSelect
                onChange={AppStore.changeGender}
                gender={AppStore.gender}
                className={`header-gender${genderVisible ? ' visible' : ''}`}
              />
            </div>
            <div className='header-right'>
              {AppStore.user?.id
                ?
                <>
                  {AppStore.user.subscription?.status === ESubscriptionStatus.Active
                    ?
                    <div className='header-credits' onClick={() => navigate('/premium/credits')}>
                      <span>💎<span className='ms-1'>{AppStore.user.credits}</span></span>
                      <ReactSVG src={plus_active} className='react-icon'/>
                    </div>
                    :
                    <Button
                      href={'/premium'}
                      size='sm'
                      icon={diamond}
                      title={'SUB_OFF_75'}
                    />
                  }
                  <OutsideClick outsideClickEvent={() => setProfileVisible(false)}
                                className={`header-avatar${profileVisible ? ' visible' : ''}`}>
                    <div onClick={toggleProfileVisible} className='d-flex align-items-center'>
                      <Avatar user size='lg' image={thumb(AppStore.user?.avatar?.id, 128)}/>
                      <ReactSVG src={chevron_down} className='react-icon header-avatar-arrow'/>
                    </div>
                  </OutsideClick>
                </>
                :
                <>
                  <Button title={'REGISTER'} size='sm' onClick={() => onAuthClick('register')}/>
                  <Button title={'LOGIN'} size='sm' btnType='secondary' className='ms-2'
                          onClick={() => onAuthClick('login')}/>
                </>
              }
            </div>
          </div>
        </div>
      </header>
      <OutsideClick outsideClickEvent={() => setProfileVisible(false)}
                    className={`header-profile${profileVisible ? ' visible' : ''}`}>
        <div className='d-flex align-items-center p-2 cursor-pointer' onClick={handleSettings}>
          <ReactSVG src={settings} className='react-icon me-2'/>
          <span>{t('SETTINGS')}</span>
        </div>
        <div className='d-flex align-items-center p-2 cursor-pointer' onClick={handleLogout}>
          <ReactSVG src={logout} className='react-icon me-2'/>
          <span className='text-danger'>{t("LOGOUT")}</span>
        </div>
      </OutsideClick>
    </>
  );
})

export default Header;
