import React, {FC, useEffect, useRef, useState} from 'react';
import {Input} from "../../../../components/FormControls";
import Button from "../../../../components/Button";
import {ReactSVG} from "react-svg";
import send from "../../../../assets/icons/send.svg";
import Avatar from "../../../../components/Avatar";
import {API} from "../../../../modules/api";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import ChatStore from "../../../../store/ChatStore";
import {PhotoInterract} from "./Chat";
import Timer from "../../../../components/Timer";
import AppStore from "../../../../store/AppStore";
import useAuth from "../../../../hooks/useAuth";


interface Props {
  showImageConfirm?: PhotoInterract | boolean;
  onChangeImageConfirm: (status: boolean) => void;
}

const ChatControl: FC<Props> = observer(({onChangeImageConfirm, showImageConfirm}) => {

  const [onAuthClick] = useAuth(ChatStore.activeChat?.model?.image?.id, `/chat/${ChatStore.activeChat?.id || ''}`);
  const _timer: any = useRef(null)
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    setMessage('')
  }, [ChatStore.activeChat?.id]);

  useEffect(() => {
    if (showImageConfirm) {
      setTimer(10)
      startTimer();
    } else {

    }
  }, [showImageConfirm]);

  const startTimer = () => {
    _timer.current = setTimeout(() => {
      setTimer(prevState => {
        if (prevState < 1) {
          onChangeImageConfirm(false);
          return 0;
        } else {
          startTimer();
        }
        return prevState - 1;
      })
    }, 1000);
  }

  const onConfirmImageClick = (status: string) => async () => {
    try {
      onChangeImageConfirm(false);
      await API.Interract.send((showImageConfirm as PhotoInterract)?.interract_id, {data: status});
    } catch (e) {
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!AppStore.user?.id) return onAuthClick();
    if (!message) return;
    try {
      setMessage('');
      await API.Conversations.sendMessage(ChatStore.activeChat?.id!, {text: message});
    } catch (e: any) {
      toast.error(e);
    }
  }

  return (
    <>
      <div className={`chat-control${showImageConfirm ? ' confirm-visible' : ''}`}>
        {!showImageConfirm
          ?
          <div className="chat-input">
            <form onSubmit={onSubmit}>
              <div className='position-relative d-flex align-items-center'>
                <Input
                  autoFocus
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className='lg'
                  placeholder={'TYPE_MESSAGE'}
                />
                <Button type='submit' contentClassName='p-0'>
                  <ReactSVG src={send} className='react-icon'/>
                </Button>
                <Avatar user size='xxs'/>
              </div>
            </form>
          </div>
          :
          <div className='chat-confirm-image'>
            <div className='px-2'>{(showImageConfirm as PhotoInterract)?.question}</div>
            <div className='chat-confirm-image-footer'>
              <Button
                className='w-50 me-1'
                onClick={onConfirmImageClick('yes')}
                size='sm'
                title='YES_WANT_IMAGE'
              />


              <div className='ms-1 position-relative w-50 d-flex align-items-center'>
                <Button
                  className='w-100'
                  btnType='secondary'
                  onClick={onConfirmImageClick('no')}
                  // onClick={() =>openModalNoMoney('chat')}
                  size='sm'
                  title="DONT_WANT_IMAGE"
                />
                <Timer
                  time={timer}
                  disabled={timer < 1}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
})

export default ChatControl;