import React, {FC, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {thumb} from "../../../modules/utils";
import {API} from "../../../modules/api";
import {ESortOrder, IModel, IPhoto} from "../../../modules/rest";
import Slider from "react-slick";
import {openGalleryModal} from "../../../modals";
import {ReactSVG} from "react-svg";
import expand from "../../../assets/icons/expand.svg";
import AppStore from "../../../store/AppStore";
import {useTranslation} from "react-i18next";
import BlurComponent from "../../../components/BlurComponent";


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface Props {
  className?: string;
  canOpenPhoto?: boolean;
  model?: IModel;
}

const ModelProfile: FC<Props> = observer(({
                                            model,
                                            className,
                                            canOpenPhoto = true,
                                          }) => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState<IPhoto[]>([]);

  useEffect(() => {
    if (model?.id && AppStore.user?.id) getGallery();
  }, [model?.id]);

  const getGallery = async () => {
    setLoading(true)
    try {
      const gallery = await API.Models.getPhotos(model?.id!, {limit: 5, order: ESortOrder.ASC});
      setGallery(gallery.data);
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }
  // if (!model) return null;
  return (

    <div className={`model-profile ${className || ''}`}>
      <div className="model-profile-gallery">
        {loading || !gallery?.length
          ?
          <BlurComponent isNsfw={model?.image?.isNsfw} className='model-profile-slide'
                         onClick={canOpenPhoto ? () => openGalleryModal({image: model?.image}) : undefined}>
            <img src={thumb(model?.image?.id, 800)} alt="img"/>
          </BlurComponent>
          :
          <div className='btn-expand-container'>
            {canOpenPhoto && <ReactSVG src={expand} className='react-icon btn-expand'/>}
            <Slider {...settings} >
              {gallery.map((item, idx) => (
                <BlurComponent isNsfw={item?.photo?.isNsfw} className='model-profile-slide' key={item.id}
                     onClick={canOpenPhoto ? () => openGalleryModal({image: gallery.map(item => item.photo), initialIdx: idx}) : undefined}>
                  <img src={thumb(item.photo.id, 800)} alt="img"/>
                </BlurComponent>
              ))}
            </Slider>
          </div>
        }
      </div>
      {model
        ?
        <div className='model-profile-info'>
          <h4>{model?.name}</h4>
          <p className='text-dark mt-3'>{model?.aboutLocal}</p>
          <hr/>
          <div className='text-bold'>{t('PERSONALITY_ATTRIBUTES')}</div>
          <div className='model-profile-cards'>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('PERSONALITY')}</div>
              <div className='text-semibold-14'>{model?.personalityLocal?.split('(')?.[0]}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('OCCUPATION')}</div>
              <div className='text-semibold-14'>{model?.occupationLocal}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('HOBBIES')}</div>
              <div className='text-semibold-14'>{model?.hobbiesLocal?.join(', ')}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('RELATIONSHIP')}</div>
              <div className='text-semibold-14'>{model?.relationshipLocal}</div>
            </div>
          </div>
          <div className='mt-4 text-bold'>{t('PHYSICAL_ATTRIBUTES')}</div>
          <div className='model-profile-cards'>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('BODY')}</div>
              <div className='text-semibold-14 letter-uppercase'>{t(model?.bodyType?.toUpperCase()!)}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('AGE')}</div>
              <div className='text-semibold-14'>{model?.age}</div>
            </div>
            <div className="model-profile-card">
              <div className='text-dark-14 mb-2'>{t('ETHNICITY')}</div>
              <div className='text-semibold-14 letter-uppercase'>{t(model?.ethnicity?.toUpperCase()!)}</div>
            </div>

          </div>
        </div>
        :
        null
      }
    </div>
  );
})

export default ModelProfile;