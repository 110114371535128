import React, {FC} from 'react';
import chatStore from "../../../../store/ChatStore";
import Message from "../../../../components/Message";
import ChatStore from "../../../../store/ChatStore";
import {ESender} from "../../../../modules/rest";
import {observer} from "mobx-react";

interface Props {
  loading: boolean;
  typing: boolean;
  uploading: boolean;
}

const ChatBoard: FC<Props> = observer(({typing, uploading, loading}) => {
  return (
    <div >
      {/*{(loading) &&*/}
      {/*  <div className='d-flex justify-content-center'>*/}
      {/*    <Spinner loading absolute style={{marginTop: -8}}/>*/}
      {/*  </div>*/}
      {/*}*/}
      {chatStore.messages?.map((item, i) => (
        <Message
          avatarId={ChatStore.activeChat?.model.image?.id}
          data={item}
          key={item.id}
          hideAvatar={item.sender === chatStore.messages[i + 1]?.sender || (i === chatStore.messages.length - 1 && item.sender === ESender.Model && typing)}
        />
      ))}
      {typing && <Message avatarId={ChatStore.activeChat?.model?.image?.id} typing/>}
      {uploading && <Message avatarId={ChatStore.activeChat?.model?.image?.id} uploading/>}
    </div>
  );
})

export default ChatBoard;