import React, {FC,  useState} from 'react';
import {observer} from "mobx-react";
import {HeaderSecondary} from "../../../containers/Header";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {IAddCreditsRequest, ICreateSubscriptionRequest} from "../../../modules/rest";
import {API} from "../../../modules/api";
import {toast} from "react-toastify";
import Button from "../../../components/Button";
import card from "../../../assets/icons/card.svg";
import {Input, Select} from "../../../components/FormControls";
import geos from '../../../translates/en/geos.json'
import states from '../../../translates/en/states.json'
import {ReactSVG} from "react-svg";
import premium_svg from '../assets/icons/premium.svg'
import credits_svg from '../assets/icons/credits.png'
import AppStore from "../../../store/AppStore";

interface Props {
}

const BillingAddressPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {paymentType, paymentPlan, paymentMethodId} = useParams();

  const [loading, setLoading] = useState(false);
  const storagePaymentDetails = localStorage.getItem('paymentDetails')
  const [paymentDetails, setPaymentDetails] = useState<Record<string, any>>({
    bill_email: AppStore.user?.email,
    billName: AppStore.user?.name,
    ...(storagePaymentDetails ? JSON.parse(storagePaymentDetails) : {})
  });

  const handleChange = (key: string) => (e: any) => {
    const value = e.target.value;
    setPaymentDetails((prevState: any) => ({...prevState, [key]: value}));
    if(key === 'bill_country') {
      //@ts-ignore
      setPaymentDetails((prevState: any) => ({...prevState, bill_phone: geos[value]?.telcode, bill_state: ''}));
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      let res;
      if (paymentType === 'subscription') {
        res = await API.Subscriptions.createSubscription({
          period: paymentPlan as ICreateSubscriptionRequest['period'],
          method: Number(paymentMethodId)!,
          paymentDetails,
        });
      } else {
        res = await API.Subscriptions.addCredits({
          credits: paymentPlan as IAddCreditsRequest['credits'],
          method: Number(paymentMethodId)!,
          paymentDetails,
        });
      }
      localStorage.setItem('paymentDetails', JSON.stringify(paymentDetails))
      window.location.href = res.redirect;
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false)
    }
  }

  //@ts-ignore
  const billStates: any = states[paymentDetails.bill_country];

  return (
    <>
      <HeaderSecondary title='BILLING_ADDRESS' coins onClick={() => navigate(-1)} back/>
      <div className='container premium-page'>
        <h2 className='page-title'>{t('BILLING_ADDRESS')}</h2>
        <div className="premium-container">
          <form onSubmit={onSubmit} className='w-100 position-relative z-1'>
            <Input
              required
              value={paymentDetails.fullname}
              onChange={handleChange('fullname')}
              label='FULL_NAME'
              placeholder={t('FULL_NAME_PLACEHOLDER') || ''}
            />
            <Input
              required
              className='mt-3'
              type='email'
              value={paymentDetails.bill_email}
              onChange={handleChange('bill_email')}
              label='E-MAIL'
              placeholder={t('YOUR_EMAIL') || ''}
            />
            <div className="row g-3 mt-0">
              <div className="col-12 col-sm-6">
                <Input
                  required
                  value={paymentDetails.bill_address}
                  onChange={handleChange('bill_address')}
                  label='ADDRESS'
                  placeholder={t('ADDRESS') || ''}
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  required
                  value={paymentDetails.bill_city}
                  onChange={handleChange('bill_city')}
                  label='CITY'
                  placeholder={t('CITY') || ''}
                />
              </div>
              <div className="col-12 col-sm-6">
                <Select
                  required
                  value={paymentDetails.bill_country}
                  onChange={handleChange('bill_country')}
                  label='COUNTRY'
                  placeholder={'SELECT_COUNTRY'}
                >
                  {Object.entries(geos).map(([geo, value]) => (
                    <option value={geo} key={geo}>{value.name}</option>
                  ))}
                </Select>

              </div>
              <div className="col-12 col-sm-6">
                {billStates
                  ?
                  <Select
                    required
                    value={paymentDetails.bill_state}
                    onChange={handleChange('bill_state')}
                    label='STATE'
                    placeholder={'SELECT_STATE'}
                  >
                    {Object.entries(billStates).map(([geo, value]) => (
                      <option value={geo} key={geo}>{value as string}</option>
                    ))}
                  </Select>
                  :
                  <Input
                    required
                    value={paymentDetails.bill_state}
                    onChange={handleChange('bill_state')}
                    label='STATE'
                    placeholder={t('STATE') || ''}
                  />
                }

              </div>

              <div className="col-12 col-sm-6">
                <Input
                  required
                  value={paymentDetails.bill_zip}
                  onChange={handleChange('bill_zip')}
                  label='ZIP'
                  placeholder={t('ZIP') || ''}
                />
              </div>
              <div className="col-12 col-sm-6">
                <Input
                  required
                  value={paymentDetails.bill_phone}
                  onChange={handleChange('bill_phone')}
                  label='PHONE'
                  placeholder={t('PHONE') || ''}
                />
              </div>
            </div>
            {paymentType === 'subscription'
              ?
              <div className={`billing-type ${paymentType}`}>
                <div className={`billing-type-bg`}>
                  <ReactSVG src={premium_svg} className='react-icon' />
                </div>
                <div className='text-bold'>{t('YOU_WLL_GET')}</div>
                <h6 className='border-gradient-secondary mb-0 px-3'><span className='text-tint position-relative z-1'>{t('PREMIUM')}</span></h6>
              </div>
              :
              <div className={`billing-type ${paymentType}`}>
                <div className={`billing-type-bg `}>
                  <img src={credits_svg} alt="diamonds"/>
                </div>
                <div className='text-bold'>{t('YOU_WLL_GET')}</div>
                <h6 className='m-0'>💎 {paymentPlan}</h6>
              </div>
            }

            <div className='modal-footer'>
              <Button
                loading={loading}
                className='w-100'
                iconAbsolute
                icon={card}
                title='PAY_CARD'
                type={'submit'}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
})

export default BillingAddressPage;