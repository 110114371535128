import React, {FC, useState} from 'react';
import TopCarouselItem from "./TopCarouselItem";
import lines_bg from '../assets/images/lines_bg.png';
import HomeStore from "../../../store/HomeStore";
import {observer} from "mobx-react";
import {IModel} from "../../../modules/rest";
import Slider from "react-slick";
import {openGalleryModal} from "../../../modals";
import {thumb} from "../../../modules/utils";

export type TTopCarouselPosition = 'left'|'center'|'right';

const settings = {
  dots: true,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface Props {
  onAnswer: (model: IModel) => void;
}

const TopCarousel: FC<Props> = observer(({onAnswer}) => {
  const [position, setPosition] = useState<TTopCarouselPosition[]>(['center', 'right', 'left']);
  const [activeIdx, setActiveIdx] = useState(0);

  const handleClick = (i: number) => () => {
    if (position[i] === 'center') return;
    setPosition(prevState => {
      const centerIdx = prevState.findIndex(item => item === 'center');
      const newState = [...prevState];
      newState[centerIdx] = position[i];
      newState[i] = 'center';
      return newState;
    })
  }

  return (
    <div className=''>
      <div className='top-carousel'>
        {HomeStore.randomModels.map((item: any, i: number) => (
          <TopCarouselItem
            key={i}
            item={item}
            position={position[i]}
            onClick={handleClick(i)}
            onAnswer={onAnswer}
          />
        ))}
        <img src={lines_bg} alt="bg" className='top-carousel-bg'/>
      </div>
      <Slider {...settings} className='home-slider' afterChange={setActiveIdx}>
        {HomeStore.randomModels.map((item: any, i: number) => (
          <TopCarouselItem
            key={i}
            item={item}
            position={activeIdx === i ? 'center' : 'left'}
            onClick={handleClick(i)}
            onAnswer={onAnswer}
          />
        ))}
      </Slider>
    </div>
  );
})

export default TopCarousel;