import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {EFieldGroup, EModelStyle, IModel} from "../modules/rest";
import {API} from "../modules/api";
import AppStore from "./AppStore";
import {toast} from "react-toastify";

class HomeStore {
  ready: boolean = false;
  style?: EModelStyle;
  randomModels: IModel[] = [];
  models: IModel[] = [];

  constructor() {
    makeAutoObservable(this, {
      ready: observable,
      style: observable,
      randomModels: observable,
      models: observable,

      init: action,
      onCategoryChange: action,
    });
  }

  init = async (): Promise<any> => {
    try {
      const [randomModelsPager, modelsPager] = await Promise.all([
        API.Models.getList({gender: AppStore.gender, limit: 3, preset: 'random', style: EModelStyle.Real}, [EFieldGroup.ModelFull]),
        API.Models.getList({gender: AppStore.gender, limit: 100, style: EModelStyle.Real, preset: 'random'}, [EFieldGroup.ModelFull])
      ])
      runInAction(() => {
        this.randomModels = randomModelsPager.data;
        this.models = modelsPager.data?.sort( () => .5 - Math.random() );
      })
    } catch (e: any) {
      toast.error(e);
    }
  };

  onCategoryChange = async (category?: EModelStyle) => {
    if (category === this.style) return;
    try {
      runInAction(() => {
        this.style = category;
      })
      const res = await API.Models.getList({
        gender: AppStore.gender,
        limit: 100,
        style: this.style
      }, [EFieldGroup.ModelFull])
      runInAction(() => {
        this.models = res.data
      })

    } catch (e) {

    }
  }

}

export default new HomeStore();