import React, {Suspense, useEffect} from "react";
import {Route, Routes, useLocation} from 'react-router-dom';
import {observer} from "mobx-react";
import Header from "./containers/Header";
import Sider from "./containers/Sider";
import useUtm from "./hooks/useUtm";
import useToTop from "./hooks/useToTop";
import AppStore from "./store/AppStore";
import HomeStore from "./store/HomeStore";
import useSetUp from "./hooks/useSetUp";
import Footer from "./containers/Footer";

import HomePage from "./pages/HomePage";
import ChatPage from "./pages/ChatPage";
import GalleryPage from "./pages/GalleryPage";
import PaymentStatusPage from "./pages/PaymentStatusPage";
import PaymentPage from "./pages/PaymentPage";

const LegalInformationPage = React.lazy(() => import("./pages/LegalInformationPage.tsx"));
const SettingsPage = React.lazy(() => import("./pages/SettingsPage"));
const RefPage = React.lazy(() => import ("./pages/RefPage"));
const AuthPage = React.lazy(() => import ("./pages/AuthPage"));
const ModelProfilePage = React.lazy(() => import ("./pages/ModelProfilePage"));

const App = observer(() => {
  const location = useLocation();
  useToTop();
  useUtm();
  useSetUp(AppStore.user);
  // usePreloadImages();

  useEffect(() => {
    AppStore.init();
  }, []);

  useEffect(() => {
    HomeStore.init();
  }, [AppStore.gender]);

  return (
    <>

      <Header/>
      <main className={location.pathname.includes('/chat/') ? 'main-chat' : ''}>
        <Suspense>
          <Routes>
            <Route path='/' element={<HomePage/>}/>
            <Route path="/r/:id" element={<RefPage/>}/>
            <Route path="/auth/*" element={<AuthPage/>}/>
            <Route path='/chat' element={<ChatPage chatPageType='list'/>}/>
            <Route path='/chat/:id' element={<ChatPage chatPageType='chat'/>}/>
            <Route path='/model/:id' element={<ModelProfilePage/>}/>
            {/*<Route path='/chat/:id' element={<ChatPage/>}/>*/}

            <Route path='/premium/*' element={<PaymentPage/>}/>
            <Route path='/payment-status' element={<PaymentStatusPage/>}/>


            <Route path='/gallery' element={<GalleryPage/>}/>



            <Route path='/settings' element={<SettingsPage/>}/>
            <Route path='/legal-information/*' element={<LegalInformationPage/>}/>

            <Route path='*' element={<HomePage/>}/>
          </Routes>
        </Suspense>
      </main>
      <Footer/>
      <Sider/>
    </>
  )
})

export default App;