import React, {FC} from 'react';
import logo from '../../../assets/images/logo.png';
import visa from '../../../assets/images/visa.png';
import mastercard from '../../../assets/images/mastercard.png';
import {Link} from "react-router-dom";
import HomeFooterContacts from "./HomeFooterContacts";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import useAuth from "../../../hooks/useAuth";
import {useTranslation} from "react-i18next";
import {getLanguageName} from "../../../modules/utils";
import {openLanguageModal} from "../../../modals";
import {legalRoutes} from "../../LegalInformationPage.tsx/LegalInformationPage";

const info = {
  Features: [
    {title: 'CHAT', path: '/chat', target: '', needUser: true},
    {title: 'GALLERY', path: '/gallery', target: '', needUser: true},
    {title: 'BECOME_PREMIUM', path: '/premium', target: '', needUser: true},
  ],
}

interface Props {
}

const HomeFooter: FC<Props> = observer(() => {
  const {t, i18n} = useTranslation();
  const [onAuthClick] = useAuth();
  const handleClick = (item: any) => (e: any) => {
    if (item.needUser && !AppStore.user?.id) {
      e.preventDefault();
      onAuthClick()
    }
  }

  return (
    <section className='home-footer-section'>
      <div className="home-footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5 col-lg-6">
              <div className='home-footer-info'>
                <img src={logo} alt="logo" className='home-footer-logo'/>
                {/*<p className='mt-3 text-muted' style={{maxWidth: 400}}>{t('HOME_FOOTER_TEXT')}</p>*/}
                <HomeFooterContacts/>
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-6 ">
              <div className="row home-footer-nav">
                {Object.entries(info).map(([key, value]) => (
                  <div className="col-6" key={key}>
                    <div className='text-muted-14'>{t(key.toUpperCase())}</div>
                    <div className='d-flex flex-column align-items-start'>
                      {value.map((item, i) => (
                        <Link
                          onClick={handleClick(item)}
                          to={item.path}
                          target={item.target || ''}
                          className='mt-3 text-bold'
                          key={i}>{t(item.title)}</Link>
                      ))}
                    </div>
                  </div>
                ))}
                <div className="col-6">
                  <div className='text-muted-14'>{t("LANGUAGE")}</div>
                  <div className='d-flex flex-column align-items-start'>
                    <div
                      onClick={  openLanguageModal }
                      className='mt-3 text-bold text-active'>
                      {getLanguageName(i18n.language)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeFooterContacts className='bottom'/>
          <div className='d-flex mt-4 justify-content-end'>
            <div className='home-footer-payment'>
              <img src={visa} alt="visa"/>
            </div>
            <div className='home-footer-payment'>
              <img src={mastercard} alt="mastercard"/>
            </div>
          </div>
          <div className="home-footer-bottom">
            <div className='d-flex align-items-center text-14'>
              <Link to={'/legal-information/privacy-policy'}>{t('PRIVACY_POLICY')}</Link>
              <div className='mx-2'>&#x2022;</div>
              <a href={legalRoutes[0].href} target='_blank'>{t('TERMS_OF_SERVICE')}</a>
            </div>
            <div className='text-14'>
              <span className='text-muted'>{t('COMPANY_C')} - </span><Link to={'/sitemap'}>{t('SITEMAP')}</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
})

export default HomeFooter;