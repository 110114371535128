import {openModalLogin} from "../modals";
import {useLocation, useNavigate} from "react-router-dom";


const useAuth = (defaultImageId?: string | null, redirectPathname?: string, onSuccessAuth?: () => void): [onClick: (authType?: 'login'|'register') => void, finishedStatus?: boolean] => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (authType?: 'login'|'register') => {
    if (window.innerWidth < 768) {
      navigate(`/auth/${authType || 'login'}`, {state: {prevPathname: redirectPathname || location.pathname}});
    } else {
      openModalLogin(authType, defaultImageId).then(success => {
        if(typeof success === "number" && location.pathname.includes('/chat')) {
          navigate(`/chat/${success}`)
        }
        if(success && onSuccessAuth) {
          onSuccessAuth();
        }
      });
    }
  }

  return [handleClick];
}

export default useAuth;