import React, {FC} from "react";
import {useTranslation} from "react-i18next";

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: FC<Props> = ({error, resetErrorBoundary}) => {
  const {t} = useTranslation();

  return (
    <div className="d-flex justify-content-center">
      <div className="alert alert-danger mt-5" style={{width: 800}}>
        <p className="lead"> {error.message}</p>
        <details className="cursor-pointer mb-3">
          <pre className="p-3 border-1 bg-light">{error.stack}</pre>
        </details>

        <div className="d-flex">
          <button type='button' onClick={resetErrorBoundary} className="me-2 px-4">
            {t("SKIP")}
          </button>
          <button type='button' onClick={window.location.reload} className="me-2 px-4">
            {t("RELOAD")}
          </button>
          <button type='button' onClick={() => window.location.replace('/')} className="me-2 px-4">
            {t("START_OVER")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
