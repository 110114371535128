import React, {FC} from 'react';
import {IConversation} from "../../../../modules/rest";
import Avatar from "../../../../components/Avatar";
import {thumb, thumbAvatar} from "../../../../modules/utils";

interface Props {
  data: IConversation;
  active?: boolean;
  onClick: () => void;
}

const ChatsListItem: FC<Props> = ({data, active, onClick}) => {
  return (
    <div className={`chats-list-item${active ? ' active' : ''}`} onClick={onClick}>
      <Avatar image={thumbAvatar(data.model?.image?.id, 128)}/>
      <div className='ms-2 flex-1'>
        <div className='d-grid overflow-hidden'>
          <div className='text-bold text-truncate'>{data.model?.name}</div>
        </div>
        {data.lastMessage ?
          <div className='chats-list-item-message text-14'>
            {data.lastMessage.photo
            ?
              <div>
                <img src={thumb(data.lastMessage.photo?.id, 128)} alt=""/>
                <span className='ms-1'>Photo</span>
              </div>
            :
              <p className='text-truncate'>{data.lastMessage?.text}</p>
            }
          </div>
          : null
        }
      </div>
    </div>
  );
}

export default ChatsListItem;