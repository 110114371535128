import React, {FC, HTMLAttributes} from 'react';
import {thumb} from "../../modules/utils";
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import {ReactSVG} from "react-svg";
import user_svg from './assets/icons/user.svg';

interface Props extends HTMLAttributes<any> {
  image?: string;
  className?: string;
  user?: boolean;
  size?: 'xl'|'lg'|'sm'|'xs'|'xxs'
}

const Avatar: FC<Props> = observer(({image, size, className, user, ...props}) => {
  return (
    <div className={`avatar ${user || !image ? ' user' : ''} ${size || ''} ${className || ''}`} {...props}>
      {image ? <img src={image} alt="avatar"/>
        :
        user
          ?
          AppStore.user?.avatar ? <img src={thumb(AppStore.user.avatar.id, 204)} alt="avatar"/>
            :
            <ReactSVG src={user_svg} className='react-icon'/>
          : null
      }
    </div>
  );
})

export default Avatar;