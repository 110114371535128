export type THttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type THeaders = Record<string, string>;

export type TValidationError = {
  key: string;
  name: string;
  error: string;
};

export type TResponse = {
  result?: any;
  error?: string;
  code?: number;
  validation?: TValidationError;
};

class RestAPI {
  private readonly url: string;
  private token: string | null = null;
  private statusCode: number = 0;
  private instances: Record<string, object> = {};
  private authErrorHandler?: () => void;
  private headersHandler?: (headers: THeaders) => void;
  public validation?: TValidationError;
  public debug: boolean = false;

  constructor(url: string, debug: boolean) {
    this.url = url;
    this.debug = debug;
  }

  public getUrl = (): string => {
    return this.url;
  };

  setAuthErrorHandler = (handler?: () => void) => {
    this.authErrorHandler = handler;
  };

  setHeadersHandler = (handler?: (headers: THeaders) => void) => {
    this.headersHandler = handler;
  };

  setToken = (token: string | null): this => {
    this.token = token;
    return this;
  };

  getToken = (): string | null => {
    return this.token;
  };

  getStatusCode = (): number => {
    return this.statusCode;
  };

  get = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("GET", endpoint, payload, fields);
  };

  post = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("POST", endpoint, payload, fields);
  };

  put = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("PUT", endpoint, payload, fields);
  };

  patch = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("PATCH", endpoint, payload, fields);
  };

  delete = <T>(
    endpoint: string,
    payload?: object | FormData,
    fields?: string[]
  ): Promise<T> => {
    return this.request("DELETE", endpoint, payload, fields);
  };

  private request = <T>(
    method: THttpMethod,
    endpoint: string,
    payload: object | FormData = {},
    fields: string[] = []
  ): Promise<T> => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      const processReject = (
        error: string,
        code: number,
        validation?: TValidationError
      ) => {
        this.validation = validation;
        if (this.debug) console.error("Error", error, validation);
        if (code === 401 && this.authErrorHandler) this.authErrorHandler();
        else reject(error);
      };

      const options: {
        method: string;
        headers: THeaders;
        body?: FormData | string;
      } = {
        method: method.toUpperCase(),
        headers: {
          accept: "application/json",
        },
      };

      if (payload instanceof FormData) {
        payload.append("fields", fields.join(","));
        options.body = payload;
      } else {
        options.headers["content-type"] = "application/json";
        // @ts-ignore
        payload["fields"] = fields;
        if (payload && method !== "GET") options.body = JSON.stringify(payload);
      }

      if (this.token) {
        options.headers["authorization"] = "Bearer " + this.token;
      }

      this.statusCode = 0;
      this.validation = undefined;

      if (payload && method === "GET") {
        endpoint += "?__payload=" + encodeURIComponent(JSON.stringify(payload));
      }

      if (this.debug)
        console.log(
          "Request",
          method,
          endpoint.split("?")[0],
          JSON.parse(JSON.stringify(payload))
        );

      if (this.headersHandler) {
        this.headersHandler(options.headers);
      }

      fetch(this.url + endpoint, options)
        .then((response) => {
          this.statusCode = response.status;
          response
            .json()
            .then((data: TResponse) => {
              if (data.error)
                processReject(data.error, response.status, data.validation);
              else {
                if (this.debug) console.info("Result", data.result);
                resolve(data.result);
              }
            })
            .catch((e) => processReject(e, -2));
        })
        .catch((e) => processReject(e, -1));
    });
  };

  get Models(): Models {
    return (
      (this.instances["Models"] as Models) ??
      (this.instances["Models"] = new Models(this))
    );
  }

  get Interract(): Interract {
    return (
      (this.instances["Interract"] as Interract) ??
      (this.instances["Interract"] = new Interract(this))
    );
  }

  get Assets(): Assets {
    return (
      (this.instances["Assets"] as Assets) ??
      (this.instances["Assets"] = new Assets(this))
    );
  }

  get Users(): Users {
    return (
      (this.instances["Users"] as Users) ??
      (this.instances["Users"] = new Users(this))
    );
  }

  get Tasks(): Tasks {
    return (
      (this.instances["Tasks"] as Tasks) ??
      (this.instances["Tasks"] = new Tasks(this))
    );
  }

  get Params(): Params {
    return (
      (this.instances["Params"] as Params) ??
      (this.instances["Params"] = new Params(this))
    );
  }

  get PaymentMethods(): PaymentMethods {
    return (
      (this.instances["PaymentMethods"] as PaymentMethods) ??
      (this.instances["PaymentMethods"] = new PaymentMethods(this))
    );
  }

  get Gallery(): Gallery {
    return (
      (this.instances["Gallery"] as Gallery) ??
      (this.instances["Gallery"] = new Gallery(this))
    );
  }

  get Subscriptions(): Subscriptions {
    return (
      (this.instances["Subscriptions"] as Subscriptions) ??
      (this.instances["Subscriptions"] = new Subscriptions(this))
    );
  }

  get Dictionary(): Dictionary {
    return (
      (this.instances["Dictionary"] as Dictionary) ??
      (this.instances["Dictionary"] = new Dictionary(this))
    );
  }

  get Conversations(): Conversations {
    return (
      (this.instances["Conversations"] as Conversations) ??
      (this.instances["Conversations"] = new Conversations(this))
    );
  }
}

export { RestAPI };

export type TDateTime = string;

export type TDateTimeZone = string;

export type TIdentifier = string | number;

export interface IModel {
  id: number;
  owner?: IUser | null;
  name: string;
  gender: EGender;
  age: number;
  style?: EModelStyle;
  ethnicity?: EEthnicity;
  eyesColor?: EEyesColor;
  hairStyle?: EHairStyle;
  hairColor?: EHairColor;
  bodyType?: EBodyType;
  breastSize?: EBreastSize | null;
  buttSize?: EButtSize | null;
  occupation?: string;
  hobbies?: string[];
  personality?: string;
  relationship?: string;
  clothing?: string;
  image: IAsset | null;
  about?: string | null;
  welcomeMessage?: string | null;
  prompt?: string | null;
  languages?: ELanguage[] | null;
  translations?: Record<ELanguage, Record<string, any>> | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  firstName: string;
  welcomeMessageLocal?: string | null;
  aboutLocal?: string | null;
  occupationLocal?: string | null;
  personalityLocal?: string | null;
  relationshipLocal?: string | null;
  hobbiesLocal?: [];
}

export interface IAsset {
  id: string;
  name: string;
  mime: string;
  size: number;
  isS3?: boolean;
  noS3?: boolean;
  createdAt?: TDateTime;
  checkedAt?: TDateTime;
  extra?: Record<string, any>;
  comment?: string | null;
  url: string;
  duration?: number | null;
  durationString?: string | null;
  resolution?: number[] | null;
  isNsfw: boolean;
}

export interface IPaymentMethod {
  id: number;
  name: string;
  alias?: string | null;
  icon: IAsset | null;
  category: EPaymentMethodCategory;
  gateway?: EPaymentGateway;
  isBillingInfo: boolean;
  countries?: ECountry[];
  isEnabled?: boolean;
}

export interface ISubscription {
  id: number;
  extId: string | null;
  user?: IUser | null;
  gateway: EPaymentGateway;
  status: ESubscriptionStatus;
  period: number;
  price: number;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  expiresAt: TDateTime;
  creditsAddAt: TDateTime | null;
  isRecurring?: boolean;
}

export interface IUser {
  id: number;
  email: string;
  name: string | null;
  gender?: EGender | null;
  isBlocked?: boolean;
  isAdmin?: boolean;
  password?: string | null;
  credits?: number;
  utm?: [] | null;
  ip?: string | null;
  country?: ECountry | null;
  avatar: IAsset | null;
  fcmToken?: string | null;
  subscription?: ISubscription | null;
  language: ELanguage;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  userIdentifier?: string;
  roles?: [];
  isPaid?: boolean;
}

export interface IMessage {
  id: number;
  sender: ESender;
  text: string | null;
  photo: IAsset | null;
  createdAt: TDateTime;
}

export interface IPayment {
  id: number;
  extId: string;
  subscription?: ISubscription;
  type: EPaymentType;
  gateway: EPaymentGateway;
  amount: number;
  details?: [];
  payload?: any | null;
  createdAt?: TDateTime;
}

export interface IPhoto {
  id: number;
  model?: IModel;
  photo: IAsset;
  comment: string | null;
}

export interface IParam {
  name: string;
  value: any | null;
}

export interface IConversation {
  id: number;
  user?: IUser;
  model: IModel;
  action: EAction;
  lastMessage: IMessage | null;
  counter: number;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
}

export interface IGetPhotosRequest {
  model?: number | null;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface IGetModelsListRequest {
  gender?: EGender;
  style?: EModelStyle;
  preset?: "public" | "my" | "random";
  asAdmin?: boolean;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface ICreatePhotoRequest {
  prompt: string;
  pose?: EPose;
  filter?: EFilter;
  count?: number;
  asAdmin?: boolean;
}

export interface IUpdateModelRequest {
  name?: string;
  about?: string;
  prompt?: string;
  welcomeMessage?: string;
  personality?: string;
  occupation?: string;
  hobbies?: string[];
  relationship?: string;
  translations?: Record<ELanguage, Record<string, any>>;
}

export interface ICreateModelRequest {
  gender: EGender;
  style: EModelStyle;
  age: number;
  ethnicity: EEthnicity;
  eyesColor: EEyesColor;
  hairStyle: EHairStyle;
  hairColor: EHairColor;
  bodyType: EBodyType;
  breastSize?: EBreastSize | null;
  buttSize?: EButtSize | null;
  personality: string;
  occupation: string;
  hobbies: string[];
  relationship: string;
  clothing: string;
  asAdmin?: boolean;
}

export interface IGetParamsRequest {
  keys: string[];
}

export interface ISetParamsRequest {
  params: Record<string, any>;
}

export interface ISendMessageRequest {
  text: string;
}

export interface IGetMessagesRequest {
  lastMessageId?: number | null;
  limit?: number;
}

export interface IUtm {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_term: string | null;
  esub: string | null;
  pub_id: string | null;
}

export interface IUploadStatus {
  id: string;
  fileName?: string;
  fileType?: string;
  fileSize?: number;
  uploaded?: number;
  progress: number;
  asset: IAsset | null;
  request?: IUploadRequest;
  isReady?: boolean;
}

export interface IUploadRequest {
  fileName: string;
  fileSize: number;
  fileType?: string;
  noS3?: boolean;
}

export interface ICreatePaymentMethodRequest {
  category: EPaymentMethodCategory;
  gateway: EPaymentGateway;
  name: string;
  alias?: string | null;
  icon: string;
  isEnabled?: boolean;
  isBillingInfo?: boolean;
  countries: ECountry[];
}

export interface IUpdatePaymentMethodRequest {
  category?: EPaymentMethodCategory;
  gateway?: EPaymentGateway;
  name?: string;
  alias?: string | null;
  icon?: string;
  isEnabled?: boolean;
  isBillingInfo?: boolean;
  countries?: ECountry[];
}

export interface IGetPaymentMethodsRequest {
  query?: string | null;
  gateway?: EPaymentGateway | null;
  category?: EPaymentMethodCategory | null;
  country?: ECountry | null;
  asAdmin?: boolean;
}

export interface ICommonGetListRequest {
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface ISendInterractDataRequest {
  data?: any | null;
}

export interface ICreateSubscriptionRequest {
  period: "1" | "3" | "12";
  method: number;
  paymentDetails?: Record<string, any>;
}

export interface IGetSubscriptionsListRequest {
  user?: number;
  status?: ESubscriptionStatus;
  method?: number;
  gateway?: EPaymentGateway;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface IGetPaymentsListRequest {
  user?: number;
  subscription?: number;
  type?: EPaymentType;
  method?: number;
  gateway?: EPaymentGateway;
  query?: string;
  order?: ESortOrder;
  page?: number;
  limit?: number;
}

export interface IAddCreditsRequest {
  credits: "100" | "350" | "550" | "1150" | "2400" | "3750";
  method: number;
  paymentDetails?: Record<string, any>;
}

export interface ILoginRequest {
  email: string;
  password: string;
  ttl?: number;
}

export interface ILoginWithGoogleRequest {
  accessToken: string;
  utm?: IUtm | null;
}

export interface IUpdateProfileRequest {
  name?: string;
  gender?: EGender;
  password?: string;
  avatarId?: string | null;
  language?: ELanguage;
  fcmToken?: string | null;
}

export interface IPasswordResetRequest {
  email: string;
}

export interface IRegisterRequest {
  email: string;
  password: string;
  utm?: IUtm | null;
}

export interface ICreateModelTask {
  type: "model";
  result: number | null;
  id: string;
  title: string | null;
  description: string | null;
  status: ETaskStatus;
  progress: number | null;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  error: string | null;
}

export interface ICreateModelMainPhotoTask {
  modelId?: number;
  type: "photo";
  result: IPhoto | null;
  id: string;
  title: string | null;
  description: string | null;
  status: ETaskStatus;
  progress: number | null;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  error: string | null;
}

export interface ICreateModelExtraPhotosTask {
  type: "photo";
  result: IPhoto[] | null;
  id: string;
  title: string | null;
  description: string | null;
  status: ETaskStatus;
  progress: number | null;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  error: string | null;
}

export interface IGetTasksRequest {
  type?: "photo" | "model" | null;
  showTerminal?: boolean;
}

export interface ITask {
  id: string;
  title: string | null;
  description: string | null;
  status: ETaskStatus;
  progress: number | null;
  type: string;
  createdAt: TDateTime;
  updatedAt: TDateTime;
  error: string | null;
}

export enum EModelStyle {
  Real = "Real",
  Anime = "Anime",
}

export enum EEyesColor {
  Brown = "brown",
  Blue = "blue",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}

export enum EHairColor {
  Blonde = "blonde",
  Brown = "brown",
  Brunette = "brunette",
  Black = "black",
  Ginger = "ginger",
  Gray = "gray",
  Redhead = "redhead",
  Pink = "pink",
  White = "white",
  Blue = "blue",
  Green = "green",
  Purple = "purple",
  Multicolor = "multicolor",
}

export enum EHairStyle {
  Straight = "straight",
  Braids = "braids",
  Bangs = "bangs",
  Curly = "curly",
  Bun = "bun",
  Short = "short",
  Long = "long",
  Ponytail = "ponytail",
  Pigtails = "pigtails",
  BuzzCut = "buzz-cut",
  SlickedBack = "slicked-back",
  Dreadlocks = "dreadlocks",
  Bald = "bald",
}

export enum ETaskStatus {
  Pending = "pending",
  Processing = "processing",
  Completed = "completed",
  Cancelled = "cancelled",
  Failed = "failed",
}

export enum ECountry {
  AU = "au",
  AT = "at",
  AZ = "az",
  AX = "ax",
  AL = "al",
  DZ = "dz",
  VI = "vi",
  AS = "as",
  AI = "ai",
  AO = "ao",
  AD = "ad",
  AQ = "aq",
  AG = "ag",
  AR = "ar",
  AM = "am",
  AW = "aw",
  AF = "af",
  BS = "bs",
  BD = "bd",
  BB = "bb",
  BH = "bh",
  BZ = "bz",
  BY = "by",
  BE = "be",
  BJ = "bj",
  BM = "bm",
  BG = "bg",
  BO = "bo",
  BQ = "bq",
  BA = "ba",
  BW = "bw",
  BR = "br",
  IO = "io",
  VG = "vg",
  BN = "bn",
  BF = "bf",
  BI = "bi",
  BT = "bt",
  VU = "vu",
  VA = "va",
  GB = "gb",
  HU = "hu",
  VE = "ve",
  UM = "um",
  TL = "tl",
  VN = "vn",
  GA = "ga",
  HT = "ht",
  GY = "gy",
  GM = "gm",
  GH = "gh",
  GP = "gp",
  GT = "gt",
  GF = "gf",
  GN = "gn",
  GW = "gw",
  DE = "de",
  GG = "gg",
  GI = "gi",
  HN = "hn",
  HK = "hk",
  GD = "gd",
  GL = "gl",
  GR = "gr",
  GE = "ge",
  GU = "gu",
  DK = "dk",
  JE = "je",
  DJ = "dj",
  DM = "dm",
  DO = "do",
  CD = "cd",
  EU = "eu",
  EG = "eg",
  ZM = "zm",
  EH = "eh",
  ZW = "zw",
  IL = "il",
  IN = "in",
  ID = "id",
  JO = "jo",
  IQ = "iq",
  IR = "ir",
  IE = "ie",
  IS = "is",
  ES = "es",
  IT = "it",
  YE = "ye",
  CV = "cv",
  KZ = "kz",
  KY = "ky",
  KH = "kh",
  CM = "cm",
  CA = "ca",
  QA = "qa",
  KE = "ke",
  CY = "cy",
  KG = "kg",
  KI = "ki",
  TW = "tw",
  KP = "kp",
  CN = "cn",
  CC = "cc",
  CO = "co",
  KM = "km",
  CR = "cr",
  CI = "ci",
  CU = "cu",
  KW = "kw",
  CW = "cw",
  LA = "la",
  LV = "lv",
  LS = "ls",
  LR = "lr",
  LB = "lb",
  LY = "ly",
  LT = "lt",
  LI = "li",
  LU = "lu",
  MU = "mu",
  MR = "mr",
  MG = "mg",
  YT = "yt",
  MO = "mo",
  MK = "mk",
  MW = "mw",
  MY = "my",
  ML = "ml",
  MV = "mv",
  MT = "mt",
  MA = "ma",
  MQ = "mq",
  MH = "mh",
  MX = "mx",
  FM = "fm",
  MZ = "mz",
  MD = "md",
  MC = "mc",
  MN = "mn",
  MS = "ms",
  MM = "mm",
  NA = "na",
  NR = "nr",
  NP = "np",
  NE = "ne",
  NG = "ng",
  NL = "nl",
  NI = "ni",
  NU = "nu",
  NZ = "nz",
  NC = "nc",
  NO = "no",
  AE = "ae",
  OM = "om",
  BV = "bv",
  IM = "im",
  CK = "ck",
  NF = "nf",
  CX = "cx",
  PN = "pn",
  SH = "sh",
  PK = "pk",
  PW = "pw",
  PS = "ps",
  PA = "pa",
  PG = "pg",
  PY = "py",
  PE = "pe",
  PL = "pl",
  PT = "pt",
  PR = "pr",
  CG = "cg",
  KR = "kr",
  RE = "re",
  RU = "ru",
  RW = "rw",
  RO = "ro",
  SV = "sv",
  WS = "ws",
  SM = "sm",
  ST = "st",
  SA = "sa",
  SZ = "sz",
  MP = "mp",
  SC = "sc",
  BL = "bl",
  MF = "mf",
  PM = "pm",
  SN = "sn",
  VC = "vc",
  KN = "kn",
  LC = "lc",
  RS = "rs",
  SG = "sg",
  SX = "sx",
  SY = "sy",
  SK = "sk",
  SI = "si",
  SB = "sb",
  SO = "so",
  SD = "sd",
  SR = "sr",
  US = "us",
  SL = "sl",
  TJ = "tj",
  TH = "th",
  TZ = "tz",
  TC = "tc",
  TG = "tg",
  TK = "tk",
  TO = "to",
  TT = "tt",
  TV = "tv",
  TN = "tn",
  TM = "tm",
  TR = "tr",
  UG = "ug",
  UZ = "uz",
  UA = "ua",
  WF = "wf",
  UY = "uy",
  FO = "fo",
  FJ = "fj",
  PH = "ph",
  FI = "fi",
  FK = "fk",
  FR = "fr",
  PF = "pf",
  TF = "tf",
  HM = "hm",
  HR = "hr",
  CF = "cf",
  TD = "td",
  ME = "me",
  CZ = "cz",
  CL = "cl",
  CH = "ch",
  SE = "se",
  SJ = "sj",
  LK = "lk",
  EC = "ec",
  GQ = "gq",
  ER = "er",
  EE = "ee",
  ET = "et",
  ZA = "za",
  GS = "gs",
  SS = "ss",
  JM = "jm",
  JP = "jp",
  WW = "ww",
}

export enum EAction {
  None = "none",
  Waiting = "waiting",
  Typing = "typing",
  UploadPhoto = "upload_photo",
}


export enum EPaymentType {
  Subscription = "sub",
  Credits = "cred",
}

export enum EBreastSize {
  Flat = "flat",
  Small = "small",
  Medium = "medium",
  Large = "large",
  Huge = "huge",
}

export enum EBodyType {
  Petite = "petite",
  Slim = "slim",
  Athletic = "athletic",
  Voluptuous = "voluptuous",
  Curvy = "curvy",
  Muscular = "muscular",
  Wide = "wide",
}

export enum EPose {
  Default = "Default",
  POVMissionary = "POV Missionary",
  POVBlowjob = "POV Blowjob",
  POVDoggystyle = "POV Doggystyle",
  CuminMouth = "Cum in Mouth",
  AfterSex = "After Sex",
  Handjob = "Handjob",
  CarryingSex = "Carrying Sex",
  FlashingBoobs = "Flashing Boobs",
  BreastSqueeze = "Breast Squeeze",
  FrontViewCowgirl = "Front View Cowgirl",
  ButtJob = "ButtJob",
  SideViewBlowjob = "Side View Blowjob",
  StickoutTongue = "Stick out Tongue",
  POVSpitroast = "POV Spitroast",
  JustBeforeSex = "Just Before Sex",
  POVThreesomeBlowJob = "POV Threesome BlowJob",
  GrabAss = "Grab Ass",
  MatingPress = "Mating Press",
  POVReverseCowgirl = "POV Reverse Cowgirl",
  ThighSex = "Thigh Sex",
  POVAnal = "POV Anal",
  Piledrive = "Piledrive",
  VaginaSpread = "Vagina Spread",
  ImminentSex = "Imminent Sex",
  FemaleMasturbation = "Female Masturbation",
  JackoPose = "Jacko Pose",
  SidewayAss = "Sideway Ass",
  Titjob = "Titjob",
  TitjobReal = "Titjob Real",
  TitjobAnime = "Titjob Anime",
  SpreadAss = "Spread Ass",
  Bukkake = "Bukkake",
  Spooning = "Spooning",
  AmazonPosition = "Amazon Position",
  OnOffClothing = "On/Off Clothing",
  ShirtPull = "Shirt Pull",
  CheekBulgeBlowjob = "Cheek Bulge Blowjob",
  SoloBreastGrab = "Solo Breast Grab",
  FromBelow = "From Below",
  RestingOnStomach = "Resting On Stomach",
  BlowjobUnderDesk = "Blowjob Under Desk",
  Penis = "Penis",
  BreastsPressedAgainstGlass = "Breasts Pressed Against Glass",
  ReverseDeepthroat = "Reverse Deepthroat",
  Orgy = "Orgy",
  CumonAss = "Cum on Ass",
  Cumshot = "Cumshot",
  AssOnGlass = "Ass On Glass",
  MultipleHands = "Multiple Hands",
  Facesitting = "Facesitting",
  OnOff = "On Off",
  SideBlowjob = "Side Blowjob",
  Kneeling = "Kneeling",
  POVBreastGrab = "POV Breast Grab",
  SurroundedbyPenises = "Surrounded by Penises",
  OrgasmFace = "Orgasm Face",
  FlashingInPublic = "Flashing In Public",
  Mooning = "Mooning",
  WetTshirt = "Wet Tshirt",
  LesbianOral = "Lesbian Oral",
  LesbianFingering = "Lesbian Fingering",
  LesbianScissoring = "Lesbian Scissoring",
  GayCowboyAnime = "Gay Cowboy Anime",
  GayCowboyReal = "Gay Cowboy Real",
  GayGrabbing = "Gay Grabbing",
  BDSMSuspension = "BDSM Suspension",
  BDSMTiedUp = "BDSM Tied Up",
  BDSMTape = "BDSM Tape",
  BDSMBallgag = "BDSM Ballgag",
  BDSMLeash = "BDSM Leash",
  Downblouse = "Downblouse",
  Sitting = "Sitting",
  Vagina = "Vagina",
  Showering = "Showering",
  CumBath = "Cum Bath",
  Cuddling = "Cuddling",
  LesbianCuddling = "Lesbian Cuddling",
  GayCuddling = "Gay Cuddling",
  POVCowgirl = "POV Cowgirl",
  Feet = "Feet",
  MultipleMooning = "Multiple Mooning",
  Giant = "Giant",
  Upset = "Upset",
  Disgusted = "Disgusted",
  Scared = "Scared",
  Winking = "Winking",
  Angry = "Angry",
  Smiling = "Smiling",
  Laughing = "Laughing",
  Ouch = "Ouch",
  Shocked = "Shocked",
  SideBlowjobReal = "Side Blowjob Real",
  SuckingNipple = "Sucking Nipple",
  FingerSucking = "Finger Sucking",
  ShowingoffAss = "Showing off Ass",
  WindLiftNipSlip = "Wind Lift/ Nip Slip",
  ReverseBlowjob = "Reverse Blowjob",
  LyingDownFeet = "Lying Down Feet",
  Footjob = "Footjob",
  Dildo = "Dildo",
  HandbraHoldingBoobs = "Handbra/Holding Boobs",
  MilkingMachine = "Milking Machine",
  PantiesOff = "Panties Off",
  DoubleHandjob = "Double Handjob",
  Undressing = "Undressing",
  BubbleBath = "Bubble Bath",
  CaughtNakedEmbarrassed = "Caught Naked Embarrassed",
  Gloryhole = "Gloryhole",
  XrayGlasses = "Xray Glasses",
  ManGrabbingBoobs = "Man Grabbing Boobs",
  GloryWall = "Glory Wall",
  WindLift = "Wind Lift",
  POVDeepthroat = "POV Deepthroat",
  POVStrangling = "POV Strangling",
  LickingDick = "Licking Dick",
}

export enum EPaymentGateway {
  CryptoCloud = "cryptocloud",
  Payssion = "payssion",
  PayCly = "paycly",
}

export enum EButtSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
  Skinny = "skinny",
  Athletic = "athletic",
}

export enum EPaymentMethodCategory {
  Card = "card",
  Crypto = "crypto",
  Other = "other",
}

export enum ESubscriptionStatus {
  Pending = "pending",
  Active = "active",
  Expired = "expired",
}

export enum ELanguage {
  English = "en",
  Russian = "ru",
  Spanish = "es",
  French = "fr",
  German = "de",
  Japanese = "ja",
  Italian = "it",
  Korean = "ko",
  Portuguese = "pt",
  Indonesian = "id",
  Vietnamese = "vi",
  Thai = "th",
  Hindi = "hi",
  Malay = "ms",
}

export enum EGender {
  Male = "male",
  Female = "female",
}

export enum ESender {
  User = "user",
  Model = "model",
}

export enum EDictionaryCollection {
  Clothing = "clothing",
  Hobbies = "hobbies",
  Occupations = "occupations",
  Personalities = "personalities",
  Relationships = "relationships",
}

export enum EEthnicity {
  Caucasian = "caucasian",
  Latin = "latin",
  Asian = "asian",
  Arab = "arab",
  Afro = "afro",
  Indian = "indian",
}

export enum ESortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum EFilter {
  Default = "Default",
  Cyberpunk = "Cyberpunk",
  VHS = "VHS",
  Studio = "Studio",
  Polaroid = "Polaroid",
  Vintage = "Vintage",
}

export interface IPagedData<T> {
  page: number;
  limit: number;
  count: number | null;
  pages: number | null;
  data: T[];
}

export enum EFieldGroup {
  ModelFull = "model:full",
  ModelWelcome = "model:welcome",
  ModelAbout = "model:about",
  ModelOwner = "model:owner",
  ModelLanguages = "model:languages",
  ModelTranslations = "model:translations",
  AssetDuration = "asset:duration",
  AssetFull = "asset:full",
  AssetResolution = "asset:resolution",
  AssetS3 = "asset:s3",
  Extra = "extra",
  PaymentMethodFull = "payment-method:full",
  SubscriptionUser = "subscription:user",
  UserGender = "user:gender",
  UserFull = "user:full",
  UserRole = "user:role",
  UserSubscription = "user:subscription",
  UserUtm = "user:utm",
  UserIp = "user:ip",
  UserCountry = "user:country",
  PaymentSubscription = "payment:subscription",
  PaymentDetails = "payment:details",
  PaymentPayload = "payment:payload",
  PhotoModel = "photo:model",
  ConversationUser = "conversation:user",
}

class Models {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (
    request: IGetModelsListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IModel>> => this.api.get(`/models`, request, fields);

  create = (
    request: ICreateModelRequest,
    fields?: EFieldGroup[]
  ): Promise<ICreateModelTask> =>
    this.api.post(`/models/create`, request, fields);

  getModel = (model: TIdentifier, fields?: EFieldGroup[]): Promise<IModel> =>
    this.api.get(`/models/${model}`, {}, fields);

  updateModel = (
    model: TIdentifier,
    request: IUpdateModelRequest,
    fields?: EFieldGroup[]
  ): Promise<IModel> => this.api.patch(`/models/${model}`, request, fields);

  createMeta = (model: TIdentifier, fields?: EFieldGroup[]): Promise<IModel> =>
    this.api.post(`/models/${model}/photos/meta`, {}, fields);

  addTranslation = (
    model: TIdentifier,
    language: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IModel> =>
    this.api.post(`/models/${model}/language/${language}`, {}, fields);

  createMainPhoto = (
    model: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<ICreateModelMainPhotoTask> =>
    this.api.post(`/models/${model}/photos/main`, {}, fields);

  createExtraPhoto = (
    model: TIdentifier,
    request: ICreatePhotoRequest,
    fields?: EFieldGroup[]
  ): Promise<ICreateModelExtraPhotosTask> =>
    this.api.post(`/models/${model}/photos/extra`, request, fields);

  getPhotos = (
    model: TIdentifier,
    request: ICommonGetListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IPhoto>> =>
    this.api.get(`/models/${model}/photos`, request, fields);

  startConversation = (
    model: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IConversation> =>
    this.api.post(`/models/${model}/conversation`, {}, fields);

  deleteModel = (
    model: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.delete(`/models/${model}`, {}, fields);
}

class Interract {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  send = (
    id: TIdentifier,
    request: ISendInterractDataRequest,
    fields?: EFieldGroup[]
  ): Promise<boolean> => this.api.post(`/interract/${id}`, request, fields);
}

class Assets {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getAsset = (asset: TIdentifier, fields?: EFieldGroup[]): Promise<IAsset> =>
    this.api.get(`/assets/${asset}`, {}, fields);

  uploadForm = (form: FormData): Promise<IAsset> =>
    this.api.post(`/assets/upload/form`, form, [EFieldGroup.AssetFull]);

  partial = (
    request: IUploadRequest,
    fields?: EFieldGroup[]
  ): Promise<IUploadStatus> =>
    this.api.post(`/assets/upload/partial`, request, fields);

  chunk = (form: FormData): Promise<IUploadStatus> =>
    this.api.post(`/assets/upload/chunk`, form, [EFieldGroup.AssetFull]);
}

class Users {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getUsersList = (
    request: ICommonGetListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IUser>> => this.api.get(`/users`, request, fields);

  loginAs = (
    user: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/${user}/auth`, {}, fields);

  login = (
    request: ILoginRequest,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/login`, request, fields);

  loginWithGoogle = (
    request: ILoginWithGoogleRequest,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/login/google`, request, fields);

  register = (
    request: IRegisterRequest,
    fields?: EFieldGroup[]
  ): Promise<{ token: string; user: IUser }> =>
    this.api.post(`/users/register`, request, fields);

  resetPassword = (
    request: IPasswordResetRequest,
    fields?: EFieldGroup[]
  ): Promise<true> => this.api.post(`/users/password`, request, fields);

  getMe = (fields?: EFieldGroup[]): Promise<IUser> =>
    this.api.get(`/users/me`, {}, fields);

  updateProfile = (
    request: IUpdateProfileRequest,
    fields?: EFieldGroup[]
  ): Promise<IUser> => this.api.patch(`/users/me`, request, fields);

  getUserById = (user: TIdentifier, fields?: EFieldGroup[]): Promise<IUser> =>
    this.api.get(`/users/${user}`, {}, fields);

  deleteAccount = (fields?: EFieldGroup[]): Promise<boolean> =>
    this.api.delete(`/users/profile`, {}, fields);
}

class Tasks {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (
    request: IGetTasksRequest,
    fields?: EFieldGroup[]
  ): Promise<ITask[]> => this.api.get(`/tasks`, request, fields);

  getTask = <T>(id: TIdentifier, fields?: EFieldGroup[]): Promise<T> =>
    this.api.get(`/tasks/${id}`, {}, fields);
}

class Params {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getParams = (
    request: IGetParamsRequest,
    fields?: EFieldGroup[]
  ): Promise<Record<string, any>> => this.api.get(`/params`, request, fields);

  setParams = (
    request: ISetParamsRequest,
    fields?: EFieldGroup[]
  ): Promise<true> => this.api.post(`/params`, request, fields);
}

class PaymentMethods {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getPaymentMethods = (
    request: IGetPaymentMethodsRequest,
    fields?: EFieldGroup[]
  ): Promise<{ country: ECountry; methods: IPaymentMethod[] }> =>
    this.api.get(`/payments/methods`, request, fields);

  createPaymentMethod = (
    request: ICreatePaymentMethodRequest,
    fields?: EFieldGroup[]
  ): Promise<IPaymentMethod> =>
    this.api.post(`/payments/methods`, request, fields);

  updatePaymentMethod = (
    method: TIdentifier,
    request: IUpdatePaymentMethodRequest,
    fields?: EFieldGroup[]
  ): Promise<IPaymentMethod> =>
    this.api.patch(`/payments/methods/${method}`, request, fields);

  deletePaymentMethod = (
    method: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.delete(`/payments/methods/${method}`, {}, fields);
}

class Gallery {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getModels = (fields?: EFieldGroup[]): Promise<IModel[]> =>
    this.api.get(`/gallery/models`, {}, fields);

  getPhotos = (
    request: IGetPhotosRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<IPhoto>> => this.api.get(`/gallery`, request, fields);

  deletePhoto = (
    photo: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<boolean> =>
    this.api.delete(`/gallery/photos/${photo}`, {}, fields);
}

class Subscriptions {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getCurrentSubscription = (
    fields?: EFieldGroup[]
  ): Promise<ISubscription | null> =>
    this.api.get(`/subscriptions/current`, {}, fields);

  createSubscription = (
    request: ICreateSubscriptionRequest,
    fields?: EFieldGroup[]
  ): Promise<{ subscription: ISubscription; redirect: string }> =>
    this.api.post(`/subscriptions`, request, fields);

  addCredits = (
    request: IAddCreditsRequest,
    fields?: EFieldGroup[]
  ): Promise<{ subscription: ISubscription; redirect: string }> =>
    this.api.post(`/subscriptions/credits`, request, fields);

  getList = (
    request: IGetPaymentsListRequest,
    fields?: EFieldGroup[]
  ): Promise<IPagedData<ISubscription>> =>
    this.api.get(`/subscriptions`, request, fields);
}

class Dictionary {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getFull = (
    fields?: EFieldGroup[]
  ): Promise<Record<EGender, Record<EDictionaryCollection, string[]>>> =>
    this.api.get(`/dictionary`, {}, fields);
}

class Conversations {
  private api: RestAPI;
  constructor(api: RestAPI) {
    this.api = api;
  }

  getList = (fields?: EFieldGroup[]): Promise<IConversation[]> =>
    this.api.get(`/conversations`, {}, fields);

  getConversation = (
    conversation: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<IConversation> =>
    this.api.get(`/conversations/${conversation}`, {}, fields);

  getMessages = (
    conversation: TIdentifier,
    request: IGetMessagesRequest,
    fields?: EFieldGroup[]
  ): Promise<IMessage[]> =>
    this.api.get(`/conversations/${conversation}/messages`, request, fields);

  sendMessage = (
    conversation: TIdentifier,
    request: ISendMessageRequest,
    fields?: EFieldGroup[]
  ): Promise<IMessage> =>
    this.api.post(`/conversations/${conversation}/messages`, request, fields);

  resetConversation = (
    conversation: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.delete(`/conversations/${conversation}/messages`, {}, fields);

  deleteConversation = (
    conversation: TIdentifier,
    fields?: EFieldGroup[]
  ): Promise<true> =>
    this.api.delete(`/conversations/${conversation}`, {}, fields);
}
